import useSWR from 'swr';
import { Primitive, Result, UserSchoolGroups } from '../../../../types';
import ApiService from '../../../../services/ApiService';
import { useEffect, useState } from 'react';
import FormSelect from '../../../form/FormSelect';
import { Control, FieldErrors, FieldValues, Path } from 'react-hook-form';

type Props<T extends FieldValues> = {
  control: Control<T, string>;
  errors: FieldErrors<T>;
  schoolId: string;
}

const fetcher = (schoolId: string) => () => ApiService.getUserSchoolGroupsList(schoolId);

export const GroupSelect = <T extends FieldValues>({ control, errors, schoolId }: Props<T>) => {
  const [options, setOptions] = useState<{ label: string, value: Primitive }[]>([]);
  const { data } = useSWR<Result<UserSchoolGroups>, Error>(`/user/schools/${schoolId}/groups`, fetcher(schoolId));

  useEffect(() => {
    if(data?.statusCode === 200) {
      setOptions(data.data.map(group => ({
        label: group.name,
        value: group.id
      })));
    }
  }, [data]);


  return (
    <FormSelect control={control} errors={errors} name={"group" as Path<T>} labelPath="forms.lessonJournal.group.label" options={options} />
  );
};
