import { Lesson } from '../../types';
import { Box, Card, CardContent, Divider, ListItemButton, Stack, Typography } from '@mui/material';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { getLessonPath } from '../../utils';
import { CircularProgressWithLabel } from './CircularProgressWithLabel';

type Props = {
    lesson: Lesson;
    isStudyHistoryEnabled?: boolean;
}

const CleanCard = styled(Card)`
  width: 100%;
`;

const ItemHeader = styled(Box)<{ selected: boolean }>`
  background-color: ${(props) => props.selected ? '#2A51A8' : '#fff'};
  color: ${(props) => props.selected ? '#fff' : '#2A51A8'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
`;

const ItemBody = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const LessonListItem = ({ lesson, isStudyHistoryEnabled }: Props) => {
  const sharedBy = get(lesson, 'sharedBy.0') as unknown as { name: string };
  const { lessonId } = useParams();
  const isLessonActive = lessonId === lesson.shortId;
  const navigate = useNavigate();

  return (
    <ListItemButton sx={{ padding: 0, margin: '12px 0' }} onClick={() => navigate(getLessonPath(lesson))}
      disableGutters disableRipple autoFocus={isLessonActive}>
      <CleanCard>
        <CardContent sx={{ p: 0, width: '100%' }}>
          <ItemHeader sx={{ py: 1, px: 2 }} selected={isLessonActive}>
            <Typography variant='subtitle1'>
              {lesson.header}
            </Typography>
            { !isStudyHistoryEnabled && lesson?.stats?.average &&
              <CircularProgressWithLabel
                variant="determinate"
                value={lesson.stats.average}
                selected={isLessonActive}
              />
            }
          </ItemHeader>
          <Divider />
          <ItemBody>
            <Stack sx={{ mt: 2, mx: 2 }} spacing={0.5}>
              <Typography variant='body2'>
                <Typography variant='caption'><FormattedMessage
                  id='components.lesson.author' />:</Typography> {lesson.author.name}
              </Typography>
              {sharedBy &&
                <Typography variant='body2'>
                  <Typography variant='caption'><FormattedMessage
                    id='components.lesson.sharedBy' />:</Typography> {sharedBy.name}
                </Typography>
              }
              <Typography variant='body2'>
                <Typography variant='caption'><FormattedMessage
                  id='components.lesson.date' />:</Typography> <FormattedDate
                  value={lesson.dates.created} />
              </Typography>
            </Stack>
          </ItemBody>
        </CardContent>
      </CleanCard>
    </ListItemButton>
  );
};

export default LessonListItem;
