import React from 'react';

import 'react-quill/dist/quill.snow.css';
import { ProvideEditor } from '../hooks/editor';
import LessonEditor from '../components/LessonEditor/LessonEditor';
import { useParams } from 'react-router-dom';

const LessonPage = () => {
  const params = useParams();

  if(!params.lessonId) {
    return <div>Lesson not found</div>;
  }

  return (
    <ProvideEditor lessonId={params.lessonId} key={params.lessonId}>
      <LessonEditor key={params.lessonId} />
    </ProvideEditor>
  );
};

export default LessonPage;
