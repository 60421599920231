import { Box, Button, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from '../../hooks/utils';
import { useNavigate } from 'react-router-dom';
import { useLessons } from '../../hooks/lessons';
import ApiService from '../../services/ApiService';
import { toast } from 'react-hot-toast';
import { getLessonPath } from '../../utils';
import { useAuth } from '../../hooks/auth';
import ReactGA from 'react-ga4';

const LessonViewerToolbarBox = styled(Box)`
  padding: 0 4px;
  height: 56px;
  background-color: #fff;
  display: flex;
  align-items: center;
`;

const LessonSaveBox = styled(Box)`
  margin-left: auto;
`;

type Props = {
  token: string;
}

export const LessonViewerToolbar = ({ token }: Props) => {
  // const { increaseFontSize, decreaseFontSize } = useEditor();
  const translation = useTranslation();
  const navigate = useNavigate();
  const { refetch } = useLessons();
  const { user } = useAuth();

  const handleClick = async () => {
    if(token && !user) {
      return navigate(`/login?callbackUrl=${window.location}`);
    }
    if(token) {
      ReactGA.event({
        category: 'editor',
        action: 'saveSharedLesson',
      });

      const lessonResult = await ApiService.cloneSharedLesson(token);

      if(lessonResult.statusCode === 200) {
        toast.success(translation('toasts.cloneSharedLessonSuccessful'));
        refetch();
        navigate(getLessonPath(lessonResult.data));
      }
    }
  };

  return (
    <LessonViewerToolbarBox>
      {/*<FontZoomInOut*/}
      {/*  increaseFontSize={increaseFontSize}*/}
      {/*  decreaseFontSize={decreaseFontSize}*/}
      {/*/>*/}
      <div id="toolbar" style={{ visibility: 'hidden' }}></div>
      <LessonSaveBox>
        <Button onClick={handleClick}>Zapisz w swoich notatkach</Button>
      </LessonSaveBox>
    </LessonViewerToolbarBox>
  );
};
