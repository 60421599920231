import NavigationBar from '../components/NavigationBar/NavigationBar';
import { Box, Grid } from '@mui/material';
import LoginForm from '../forms/LoginForm';

const LoginPage = () => {
  return (
    <>
      <NavigationBar path='login' />
      <Box>
        <Grid padding={2}>
          <LoginForm />
        </Grid>
      </Box>
    </>
  );
};

export default LoginPage;
