import * as yup from 'yup';

export const loginFormSchema = yup.object({
  email: yup.string().required().email(),
  password: yup.string().required().min(8),
}).required();

export const registerFormSchema = yup.object({
  name: yup.string().required().min(3),
  email: yup.string().required().email(),
  password: yup.string().required().min(8),
  repeatedPassword: yup.string().required().test((value, ctx) => {
    if (ctx.parent.password && value !== ctx.parent.password) {
      return ctx.createError({ message: 'Passwords not match' });
    }
    return true;
  }),
}).required();

export const profileFormSchema = yup.object({
  name: yup.string().required().min(3),
  email: yup.string().required().email(),
  password: yup.string().notRequired().min(8).nullable().transform((value) => !!value ? value : null),
  repeatedPassword: yup.string().test((value, ctx) => {
    if (ctx.parent.password && value !== ctx.parent.password) {
      return ctx.createError({ message: 'Passwords not match' });
    }
    return true;
  }),
}).required();

export const resetPasswordFormSchema = yup.object({
  password: yup.string().notRequired().min(8).nullable().transform((value) => !!value ? value : null),
  repeatedPassword: yup.string().test((value, ctx) => {
    if (ctx.parent.password && value !== ctx.parent.password) {
      return ctx.createError({ message: 'Passwords not match' });
    }
    return true;
  }),
}).required();

export const lessonJournalFormSchema = yup.object({
  group: yup.string().required(),
  duration: yup.string().required(),
  canceled: yup.bool().default(false),
  canceledTooLate: yup.bool().default(false),
  dates: yup.object({
    placed: yup.date().required(),
  }),
}).required();
