import { atom } from 'recoil';
import {
  StudySessionEvaluationState,
  StudySessionState,
} from '../../types';

export const studySessionState = atom<StudySessionState | null>({
  key: 'StudySession',
  default: null,
});

export const studySessionEvaluationState = atom<StudySessionEvaluationState>({
  key: 'StudySessionEvaluationState',
  default: {
    showEvaluationBox: false,
  },
});
