import { ReorderContentAction, State } from './types';
import { reorder } from '../../utils';
import { Lesson, LessonContentItem } from '../../types';
import { findIndex } from 'lodash';
import { nanoid } from 'nanoid';

export function getReorderedContentData(state: State, action: ReorderContentAction) {
  return reorder<LessonContentItem>(state.content.data, action.payload.startIndex, action.payload.endIndex);
}

export function isParagraphIdLast(state: State, id: string) {
  const contentItemId = id.replace('p-', '').replace('h-', '');
  return findIndex(state.content.data, { id: contentItemId }) === (state.content.data.length - 1);
}

export const insert = <T extends object>(arr: Array<T>, index: number, newItem: T) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index)
];

export const getContent = (lesson?: Lesson | null) => {
  if(!lesson) {
    return {
      data: []
    };
  }

  if (!lesson.content?.data) {
    return {
      data: [getNewContentItem(), getNewContentItem()],
    };
  }
  return lesson.content;
};

export const getNewContentItem = () => {
  return {
    header: {
      delta: '[]',
    },
    paragraph: {
      delta: '[]',
    },
    id: nanoid(),
    meta: {},
  };
};
