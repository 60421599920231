import { useSocket } from '../../hooks/socket';
import { useRecoilValue } from 'recoil';
import { getCurrentCursor } from '../../recoil/study';
import { Box, Button, IconButton, styled, Typography } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import React, { ReactNode } from 'react';
import ReactGA from 'react-ga4';

const ButtonsContainerBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NavigationButtons = ({ sessionId, rightButton }: { sessionId: string, rightButton?: ReactNode }) => {
  const { socket } = useSocket();
  const currentCursor = useRecoilValue(getCurrentCursor);
  const isLast = !!(currentCursor && currentCursor.index >= currentCursor.total - 1);

  const handlePrevious = () => {
    socket.emit('study:previous-paragraph', { sessionId },
      (callback: (message: string) => void) => callback('received'));

    ReactGA.event({
      category: 'study',
      action: 'previousParagraph',
    });
  };

  const handleNext = () => {
    socket.emit('study:next-paragraph', { sessionId },
      (callback: (message: string) => void) => callback('received'));

    ReactGA.event({
      category: 'study',
      action: 'nextParagraph',
    });
  };

  const handleFinish = () => {
    socket.emit('study:finish', { sessionId },
      (callback: (message: string) => void) => callback('received'));

    ReactGA.event({
      category: 'study',
      action: 'finish',
    });
  };

  return (
    <ButtonsContainerBox>
      <IconButton disabled={currentCursor?.index === 0} onClick={handlePrevious} >
        <ArrowBack />
      </IconButton>
      { currentCursor && <Typography>{currentCursor.index + 1} / {currentCursor.total}</Typography> }
      { rightButton }
      { !isLast && !rightButton &&
        <IconButton onClick={handleNext}>
          <ArrowForward />
        </IconButton> }
      { isLast && !rightButton && <Button variant="outlined" color="error" onClick={handleFinish}>Zakończ</Button> }
    </ButtonsContainerBox>
  );
};
