import { Control, Controller, FieldErrors, FieldValues, Path, PathValue } from 'react-hook-form';
import { TextField } from '@mui/material';
import { useTranslation } from '../../hooks/utils';
import { DatePicker } from '@mui/x-date-pickers';

type Props<T extends FieldValues> = {
  control: Control<T, string>;
  errors: FieldErrors<T>;
  name: Path<T>;
  defaultValue?: PathValue<T, Path<T>>,
  labelPath: string;
}

const FormDatePicker = <T extends FieldValues>({ control, name, errors, defaultValue, labelPath }: Props<T>) => {
  const translation = useTranslation();
  const helperText = errors[name]?.message && translation(`errors.${errors[name]?.message}`);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) =>
        <DatePicker
          {...field}
          onChange={(newValue) => {
            if(newValue) {
              field.onChange(newValue);
            }
          }}
          label={translation(labelPath)}
          renderInput={(params) => <TextField {...params} helperText={helperText}/>}
        />}
    />
  );
};

export default FormDatePicker;
