import React, { memo, useEffect, useState } from 'react';
import { Box, LinearProgress, Modal, Stack, Typography } from '@mui/material';
import styled from '@emotion/styled';
import style from '../../LessonEditor/modals/style';
import { FormattedMessage } from 'react-intl';
import { useEffectOnce } from 'usehooks-ts';
import { useSocket } from '../../../hooks/socket';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';

type Props = {
    lessonId?: string;
    onClose: () => void;
}

const ModalStack = styled(Stack)`
  max-width: 480px;
  min-width: 300px;
  margin: 16px auto 0;
  display: flex;
  text-align: center;
  align-items: center;
`;

const Waiting = () => {
  useEffectOnce(() => {
    ReactGA.event({
      category: 'study',
      action: 'viewWaiting',
    });
  });

  return (
    <Typography>
      <Typography variant='h6'>
        <FormattedMessage id='components.lesson.study.mode.waiting.header' />
      </Typography>
      <Typography variant='h6'>
        <FormattedMessage id='components.lesson.study.mode.waiting.subheader' />
      </Typography>
      <Typography variant='subtitle2'>
        <FormattedMessage id='components.lesson.study.mode.waiting.subheader2' />
      </Typography>
      <LinearProgress sx={{ mt: 2 }} />
    </Typography>
  );
};

const WaitingMore = () => {
  return (
    <Typography>
      <Typography variant='h6'>
        <FormattedMessage id='components.lesson.study.mode.waiting.header' />
      </Typography>
      <LinearProgress sx={{ mt: 2 }} />
    </Typography>
  );
};

const WaitingLong = ({ id }: { id?: string | null }) => {
  const navigate = useNavigate();

  useEffectOnce(() => {
    ReactGA.event({
      category: 'study',
      action: 'viewWaitingLong',
    });

    setTimeout(() => {
      navigate(`/study/${id}`);
    }, 20000); // 20 seconds
  });

  return (
    <Typography>
      <Typography variant='h6'>
        <FormattedMessage id='components.lesson.study.mode.waitingLong.header' />
      </Typography>
      <LinearProgress sx={{ mt: 2 }} />
    </Typography>
  );
};

const Start = ({ evaluator, onClose }: { evaluator: { name: string } | null, onClose: () => void }) => {
  useEffectOnce(() => {
    ReactGA.event({
      category: 'study',
      action: 'viewStart',
    });
  });
    
  useEffectOnce(() => {
    setTimeout(() => {
      onClose();
    }, 3000);
  });

  return (
    <Typography variant='h6' sx={{ mt: 3 }}>
      <FormattedMessage id='components.lesson.study.mode.start.header' />
      <br />
      <FormattedMessage id='components.lesson.study.mode.start.subheader'
        values={{ name: evaluator?.name ?? '' }} />
    </Typography>
  );
};

enum LearnSteps {
    WAIT = 'WAIT',
    WAIT_MORE = 'WAIT_MORE',
    WAIT_LONG = 'WAIT_LONG',
    START = 'START'
}

type StudySessionState = {
    id: string;
}

const EvaluateWaitModal = memo(
  function EvaluateWaitModal({ lessonId, onClose }: Props) {
    const { socket } = useSocket();
    const [state, setState] = useState<StudySessionState | null>(null);
    const [step, setStep] = useState<LearnSteps>(LearnSteps.WAIT);
    const [evaluator] = useState<{ name: string } | null>(null);
    const link = `${window.location.origin}/study/${state?.id}`;

    useEffectOnce(() => {
      socket.emit('study:data', {
        lessonId,
      }, (err: string, data: StudySessionState) => {
        setState(data);
      });
    });

    useEffect(() => {
      const waitMoreTimeout = setTimeout(() => {
        if (step === LearnSteps.WAIT) {
          setStep(LearnSteps.WAIT_MORE);
        }
        if (step === LearnSteps.WAIT_MORE) {
          setStep(LearnSteps.WAIT_LONG);
        }
      }, 10 * 1000); // 10 seconds

      return () => {
        clearTimeout(waitMoreTimeout);
      };
    }, [step]);

    return (
      <Modal
        open
        onClose={onClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <ModalStack spacing={2}>
            {step === LearnSteps.WAIT && <Waiting />}
            {step === LearnSteps.WAIT_MORE && <WaitingMore />}
            {step === LearnSteps.WAIT_LONG && <WaitingLong id={state?.id} />}
            {step === LearnSteps.START && <Start evaluator={evaluator} onClose={onClose} />}
            {[LearnSteps.WAIT, LearnSteps.WAIT_MORE, LearnSteps.WAIT_LONG].includes(step) && state?.id &&
                      <Typography variant='subtitle2' sx={{ mt: 3 }}>
                        <FormattedMessage id='components.lesson.study.mode.missingLink' />
                        <br />
                        <br />
                        <a href={link}>{link}</a>
                      </Typography>
            }
          </ModalStack>
        </Box>
      </Modal>
    );
  },
);

export default EvaluateWaitModal;
