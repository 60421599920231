import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import Helmet from 'react-helmet';
import theme from './theme';
import { IntlProvider } from 'react-intl';
import messages from './messages';
import { BrowserRouter, Link } from 'react-router-dom';
import Routes from './routes';
import { ProvideAuth } from './hooks/auth';
import { Toaster } from 'react-hot-toast';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { ModalProvider } from 'react-modal-hook';
import ReactGA from "react-ga4";
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';
import { FlagProvider } from '@unleash/proxy-client-react';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ProvideSocket } from './hooks/socket';
import { ProvideLessons } from './hooks/lessons';
import { RecoilRoot } from 'recoil';
import CookieConsent from 'react-cookie-consent';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const ROLLBAR_CONFIG = {
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
  environment: 'production', // TODO: change to environment variable
  captureUncaught: true,
  captureUnhandledRejections: true,
};

const UNLEASH_CONFIG = {
  url: `${process.env.REACT_APP_UNLEASH_API_URL}/frontend`,
  clientKey: process.env.REACT_APP_UNLEASH_API_TOKEN as string,
  refreshInterval: 60,
  appName: 'memofriend'
};


ReactGA.initialize("G-H8B535KYLH");

const Meta = (
  <Helmet>
    <meta charSet='utf-8' />
    <title>MemoFriend</title>
  </Helmet>
);


root.render(
  <React.StrictMode>
    <RollbarProvider config={ROLLBAR_CONFIG}>
      <FlagProvider config={UNLEASH_CONFIG}>
        <ProvideSocket>
          <ThemeProvider theme={theme}>
            <IntlProvider locale='pl' messages={messages}>
              <BrowserRouter>
                <ErrorBoundary>
                  <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='pl'>
                    <CssBaseline />
                    {Meta}
                    <RecoilRoot>
                      <ProvideAuth>
                        <ModalProvider>
                          <ProvideLessons>
                            <Routes />
                            <Toaster />
                            <CookieConsent
                              buttonText="Akceptuj"
                              buttonStyle={{ backgroundColor: '#4cc4dc', color: 'black', fontSize: '13px' }}
                            >
                              {/* eslint-disable-next-line max-len */}
                              Używamy plików cookie, aby umożliwić logowanie i zarządzanie sesją użytkownika. Klikając 'Akceptuj', zgadzasz się na używanie plików cookie zgodnie z naszą <Link to="/privacy" style={{ color: 'white' }}>Polityką Prywatności</Link>.
                            </CookieConsent>
                          </ProvideLessons>
                        </ModalProvider>
                      </ProvideAuth>
                    </RecoilRoot>
                  </LocalizationProvider>
                </ErrorBoundary>
              </BrowserRouter>
            </IntlProvider>
          </ThemeProvider>
        </ProvideSocket>
      </FlagProvider>
    </RollbarProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
