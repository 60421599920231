import { Action, State } from './types';
import { ActionKind } from './enums';
import { LessonContentItem } from '../../types';
import { EditMode } from '../../enums';
import { getContent, getReorderedContentData, insert } from './utils';

const initialState = {
  editMode: EditMode.READ_ONLY,
  lesson: null,
  isDragging: false,
  isEditorReady: false,
  isLoading: false,
  studySessionId: null,
  presentationSessionId: null,
  studySessionFilters: [],
  status: {
    isSaving: false,
    lastSaved: null,
    connectionError: false,
    errorCounter: 0,
  },
};

export const getInitialState = () => {
  const userSavedFontSize = parseInt(window.localStorage.getItem('fontSize') ?? '12');

  return ({
    ...initialState,
    content: getContent(),
    settings: {
      fontSize: userSavedFontSize,
    },
  });
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
  case ActionKind.ADD_CONTENT_ITEM:
    if(action.payload.after) {
      const index = state.content.data.findIndex((item) => item.id === action.payload.after);
      return {
        ...state,
        content: {
          data: insert<LessonContentItem>(state.content.data, index + 1, action.payload.item),
        }
      };
    }
    return {
      ...state,
      content: { data: [...state.content.data, action.payload.item] },
    };
  case ActionKind.REMOVE_CONTENT_ITEM:
    return {
      ...state,
      content: {
        data: state.content.data.filter((item) => item.id !== action.payload.id)
      }
    };
  case ActionKind.SET_TAGS:
    if(state.lesson) {
      return {
        ...state,
        lesson: {
          ...state.lesson,
          tags: action.payload.tags,
        }
      };
    }
    return state;
  case ActionKind.CHANGE_LESSON:
    return {
      ...state,
      lesson: action.payload,
      editMode: EditMode.READ_ONLY,
      isEditorReady: false,
      status: {
        isSaving: false,
        lastSaved: null,
        connectionError: false,
        errorCounter: 0,
      },
      content: getContent(action.payload),
    };
  case ActionKind.SET_IS_LOADING:
    return {
      ...state,
      isLoading: action.payload,
    };
  case ActionKind.SET_IS_EDITOR_READY:
    return {
      ...state,
      isEditorReady: action.payload,
    };
  case ActionKind.SET_STUDY_SESSION_ID:
    return {
      ...state,
      studySessionId: action.payload.id,
    };
  case ActionKind.SET_PRESENTATION_SESSION_ID:
    return {
      ...state,
      presentationSessionId: action.payload.id,
    };
  case ActionKind.SET_STUDY_SESSION_FILTERS:
    return {
      ...state,
      studySessionFilters: action.payload.filters,
    };
  case ActionKind.CHANGE_STATUS:
    return {
      ...state,
      status: {
        ...action.payload,
        errorCounter: action.payload.errorCounter || state.status.errorCounter,
      },
    };
  case ActionKind.CLEAN_CONTENT:
    return {
      ...state,
      content: {
        data: [],
      }
    };
  case ActionKind.UPDATE_SETTINGS:
    return {
      ...state,
      settings: {
        ...state.settings,
        ...action.payload,
      },
    };
  case ActionKind.SET_EDIT_MODE:
    return {
      ...state,
      editMode: action.payload.editMode,
    };
  case ActionKind.REORDER_CONTENT:
    return {
      ...state,
      content: {
        ...state.content,
        data: getReorderedContentData(state, action),
      },
    };
  }
  return state;
};
