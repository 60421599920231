import styled from '@emotion/styled';
import {
  Box,
  Collapse,
  Container,
  FormControlLabel,
  IconButton,
  Paper,
  TextField, TextFieldProps, ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import React, { memo, useState } from 'react';
import { useTranslation } from '../../hooks/utils';
import { useFilters } from '../../hooks/lessons';
import { Close, FilterAlt } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers';
import { useDebouncedCallback } from 'use-debounce';

type BoxContainerProps = {
    open: boolean;
}

const SearchBarContainer = styled.div`
  width: 100%;
  margin-top: 4px;
  margin-bottom: 12px;
  display: inline-flex;
`;

const DatePickerContainer = styled.div`
  width: 100%;
  padding: 0 8px;
  margin-top: 16px;
  margin-bottom: 8px;
  display: inline-flex;
`;

const SearchBoxContainer = styled(Paper)<BoxContainerProps>`
  background-color: ${props => props.open ? '#fff' : 'transparent'};
  box-shadow: ${props => props.open ? '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)' : 'none'};
  padding: 12px;
  max-width: 480px;
  margin-left: 8px;
  margin-right: 8px;
`;

const LessonsListFilters = memo(() => {
  const translation = useTranslation();
  const [showFilters, setShowFilters] = useState(false);
  const [filters, { setQuery, setDateType, setDateFrom, setDateTo }] = useFilters();
  const [search, setSearch] = useState(filters.query);

  const debouncedSearchChange = useDebouncedCallback(
    (value: string) => {
      setQuery(value);
    },
    500
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    debouncedSearchChange(e.target.value);
  };

  const handleFiltersClick = () => {
    setShowFilters((prev) => !prev);
  };

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: 'created' | 'shared',
  ) => {
    setDateType(newAlignment);
  };

  return (
    <SearchBoxContainer open={showFilters} elevation={1}>
      <SearchBarContainer>
        <TextField
          fullWidth
          label={translation('components.search.label')}
          placeholder={translation('components.search.placeholder')}
          onChange={handleSearchChange}
          value={search}
          size='small'
        />
        <IconButton aria-label='filter' onClick={handleFiltersClick}>
          { showFilters ? <Close /> : <FilterAlt />}
        </IconButton>
      </SearchBarContainer>
      <Collapse in={showFilters}>
        <Box sx={{ width: '100%' }}>
          <Container disableGutters>
            <FormControlLabel
              value='start'
              sx={{ ml: 1 }}
              control={
                <ToggleButtonGroup
                  sx={{ ml: 1 }}
                  value={filters.dateType}
                  size='small'
                  color='primary'
                  exclusive
                  onChange={handleChange}
                  aria-label='date type'
                >
                  <ToggleButton value='created'>{translation('components.search.createdDate')}</ToggleButton>
                  <ToggleButton value='shared'>{translation('components.search.sharedDate')}</ToggleButton>
                </ToggleButtonGroup>
              }
              label={translation('components.search.date')}
              labelPlacement='start'
            />
            <DatePickerContainer>
              <DatePicker
                label={translation('components.search.from')}
                value={filters.dateRange.from}
                mask="__.__.____"
                onChange={(newValue: Date | null) => {
                  setDateFrom(newValue);
                }}
                renderInput={(params: TextFieldProps) => <TextField size='small' {...params} />}
              />
              <DatePicker
                label={translation('components.search.to')}
                value={filters.dateRange.to}
                mask="__.__.____"
                onChange={(newValue: Date | null) => {
                  setDateTo(newValue);
                }}
                renderInput={(params: TextFieldProps) => <TextField size='small' sx={{ ml: 2 }} {...params} />}
              />
            </DatePickerContainer>
          </Container>
        </Box>
      </Collapse>
    </SearchBoxContainer>
  );
});

export default LessonsListFilters;