import { Box, Button, Collapse, styled, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import LessonsList from '../LessonsList/LessonsList';
import React, { memo } from 'react';
import { useTranslation } from '../../hooks/utils';
import ApiService from '../../services/ApiService';
import { LessonType } from '../../enums';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useLessons } from '../../hooks/lessons';
import LessonsListFilters from '../LessonsList/LessonsListFilters';

const ListBox = styled(Box)`
  padding: 0 0 0 4px;
  height: calc(100vh - 144px);
  
  .lessonsList {
    padding: 4px 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
  
  .list {
    overflow-y: scroll;
    margin-top: 8px;
  }
  
  .list:active::-webkit-scrollbar-thumb,
  .list:focus::-webkit-scrollbar-thumb,
  .list:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
  .list::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    visibility: hidden;
  }

  .list::-webkit-scrollbar {
    width: 5px;
    height: 4px;
  }
`;

const LessonsListSidebar = () => {
  const translation = useTranslation();
  const navigate = useNavigate();
  const { refetch } = useLessons();

  const handleCreateLesson = async () => {
    const lessonResponse = await ApiService.createLesson({
      header: translation('components.list.newLessonHeader'),
      isPublic: false,
      type: LessonType.FLASHCARDS
    });

    refetch();

    if(lessonResponse.statusCode === 200) {
      toast.success(translation( 'toasts.createLessonSuccess'));
      navigate(`/lesson/${lessonResponse.data.header}/${lessonResponse.data.shortId}?initialMode=TEXT_EDIT`);
    } else {
      toast.error(translation( 'toasts.createLessonError'));
    }
  };


  return (
    <Collapse in={true}>
      <Box sx={{
        px: 2,
        height: 56,
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        borderRight: '1px #ddd',
      }}>
        <Button
          variant='contained'
          size='small'
          onClick={handleCreateLesson}
        >
          {translation('components.list.newLessonButton')}
        </Button>
      </Box>
      <Box sx={{ height: 36, backgroundColor: '#efefef', textAlign: 'center', paddingTop: '4px', borderRight: '1px #ddd' }}>
        <Typography variant="subtitle1"><FormattedMessage id="components.list.listHeader" /></Typography>
      </Box>
      <ListBox>
        <div className="lessonsList">
          <LessonsListFilters />
          <LessonsList />
        </div>
      </ListBox>
    </Collapse>
  );
};

export default memo(LessonsListSidebar);
