import * as React from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Tooltip } from '@mui/material';
import { DateTime } from 'luxon';

export function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number, selected: boolean, date?: string, bold?: boolean },
) {
  const createdAt = DateTime.fromISO(props?.date || '').setLocale('pl');

  const toolTipText = props.date ? `Twój stopień opanowania notatki z dnia ${createdAt.toLocaleString(DateTime.DATE_MED)}` : `Twój stopień opanowania notatki`;

  return (
    <Tooltip title={toolTipText}>
      <Box sx={{ position: 'relative', display: 'inline-flex', cursor: 'pointer' }} onClick={props.onClick}>
        <CircularProgress
          variant="determinate"
          size="40px"
          sx={{
            color: (theme) =>
              props.selected ? theme.palette.grey[200] : theme.palette.primary.main,
          }}
          {...props}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant={props.bold ? 'button' : 'caption'}
            component="div"
            color={props.selected ? 'text.white' : 'text.secondary'}
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    </Tooltip>
  );
}
