import { Control, Controller, FieldErrors, FieldValues, Path, PathValue } from 'react-hook-form';
import { InputProps, TextField, TextFieldProps } from '@mui/material';
import { useTranslation } from '../../hooks/utils';
import React from 'react';

type Props<T extends FieldValues> = {
  control: Control<T, string>;
  errors: FieldErrors<T>;
  name: Path<T>;
  defaultValue?: PathValue<T, Path<T>>,
  type: 'email' | 'text' | 'password' | 'number' | 'time',
  labelPath: string;
  inputProps?: TextFieldProps['inputProps'],
  InputProps?: InputProps;
}

const FormInputField = <T extends FieldValues>({ control, name, defaultValue, errors, labelPath, type, ...rest }: Props<T>) => {
  const translation = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) =>
        <TextField
          {...field}
          {...rest}
          type={type}
          label={translation(labelPath)}
          error={!!errors[name]}
          helperText={errors[name]?.message && translation(`errors.${errors[name]?.message}` )}
          fullWidth
        />}
    />
  );
};

export default FormInputField;
