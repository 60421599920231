import React from 'react';
import { Box, InputBase, InputLabel, Skeleton, styled } from '@mui/material';
import { useTranslation } from '../../hooks/utils';
import { Lesson } from '../../types';

const LessonHeaderBox = styled(Box)`
  height: 36px;
  background-color: #efefef;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 4px;
  padding-left: 12px;
`;

const LessonHeaderInputBox = styled(Box)`
  display: inline-flex;
  align-items: baseline;
  flex-grow: 1;
`;

const HeaderInputField = styled(InputBase)`
  flex-grow: 1;
`;

type ContainerProps = {
  fontSize: number
}

const LessonHeaderInputLabel = styled(InputLabel)<ContainerProps>`
  font-size: ${(props) => props.fontSize}px;
`;

const getLessonHeaderInputSize = (fontSize: number) => {
  if(fontSize <= 12) {
    return 'small';
  }
  if(fontSize > 12) {
    return 'medium';
  }
  return 'small';
};

type Props = {
  lesson: Lesson;
  isLoading: boolean;
}

const LessonViewerHeaderInput = ({ lesson, isLoading }: Props) => {
  const translation = useTranslation();
  const FONT_SIZE = 14;

  return (
    <LessonHeaderBox>
      <LessonHeaderInputBox>
        <LessonHeaderInputLabel htmlFor="header" fontSize={FONT_SIZE}>
          {translation('components.lesson.subject')}:
        </LessonHeaderInputLabel>
        {
          isLoading && <Skeleton sx={{ marginLeft: 1 }} animation='wave' height={24} width={120}/>
        }
        {
          !isLoading &&
          <HeaderInputField
            id="header"
            size={getLessonHeaderInputSize(FONT_SIZE)}
            sx={{ marginLeft: 1, fontSize: FONT_SIZE }}
            disabled={true}
            value={lesson.header}
          />
        }
      </LessonHeaderInputBox>
    </LessonHeaderBox>
  );
};

export default LessonViewerHeaderInput;
