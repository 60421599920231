import ReactQuill, { Quill } from 'react-quill';
import { DeltaStatic } from 'quill';
import { memo, useEffect, useRef } from 'react';
// @ts-ignore
import ImageUploader from 'quill-image-uploader';
import { styled } from '@mui/material';
import { useEditor } from '../../hooks/editor';
import { EditMode, StudyEvaluationGrade } from '../../enums';

import 'quill-image-uploader/dist/quill.imageUploader.min.css';
import ApiService from '../../services/ApiService';

Quill.register("modules/imageUploader", ImageUploader);

type Props = {
  value?: string;
  onChange: (value: string, delta: DeltaStatic) => void
  handleFocus: (editor: ReactQuill | null, id: string | null) => void
  id: string;
  marker?: StudyEvaluationGrade | null;
  readOnly?: boolean;
  hiddenMode?: boolean;
  dimmedMode?: boolean;
}

type ContainerProps = {
  fontSize: number
  marker?: StudyEvaluationGrade | null;
  dimmedMode?: boolean;
  editMode: EditMode;
  showFocus: boolean;
}

const getMarkerColor = (marker: StudyEvaluationGrade) => {
  switch (marker) {
  case StudyEvaluationGrade.GREAT:
    return 'green';
  case StudyEvaluationGrade.NOT_BAD:
    return 'yellow';
  case StudyEvaluationGrade.REPEAT:
    return 'red';
  default:
    return 'gray';
  }
};

const SingleEditorContainer = styled('div', { shouldForwardProp: (prop) => prop !== 'marker' })<ContainerProps>`
  height: 100%;
  border-left: ${(props) => props.marker ? `6px solid ${getMarkerColor(props.marker)}` : 'none'};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  opacity: ${(props) => props.dimmedMode ? 0.2 : 1};
  
  border: ${(props) => props.showFocus && props.editMode === EditMode.CONTENT_PRESENTATION ? '1px solid #000' : 'none'};
  
  .ql-container {
    font-size: ${(props) => props.fontSize}px;
  }
`;

const modules = {
  keyboard: {
    bindings: {
      tab: false,
    }
  },
  imageUploader: {
    upload: async (file: File) => {
      const response = await ApiService.uploadImage(file);

      if (response.statusCode === 200) {
        return response.data.url;
      } else {
        throw new Error(response.message);
      }
    }
  }
};

const SingleEditor = memo(({ value, onChange, id, handleFocus, readOnly, marker, dimmedMode }: Props) => {
  const editorRef = useRef<ReactQuill | null>(null);
  const { state: { settings, editMode }, setEditor, currentEditorId,  } = useEditor();

  useEffect(() => {
    if(value) {
      editorRef.current?.editor?.setContents(JSON.parse(value) as DeltaStatic);
    }
  }, [value]);

  useEffect(() => {
    setEditor(id, editorRef);
  }, [id, editorRef, setEditor]);

  return (
    <SingleEditorContainer
      fontSize={settings.fontSize}
      marker={marker}
      dimmedMode={dimmedMode}
      editMode={editMode}
      showFocus={currentEditorId === id}
    >
      <ReactQuill
        id={id}
        theme='snow'
        className='notranslate'
        style={{ height: '100%', maxWidth: '100%' }}
        onFocus={() => handleFocus(editorRef.current, id)}
        ref={editorRef}
        readOnly={readOnly}
        onChange={onChange}
        modules={{
          ...modules,
          toolbar: {
            container: `#toolbar-${id}`,
          },
        }}
        formats={['bold', 'italic', 'strike', 'underline', 'link', 'image', 'video']}
      />
    </SingleEditorContainer>);
});

export default SingleEditor;
