import Lottie from 'lottie-react';
import { StudyEvaluationGrade } from '../../enums';
import Heart from './lotties/heart.json';
import Thumb from './lotties/thumb.json';
import Repeat from './lotties/repeat.json';
import { Box, keyframes, styled, Typography } from '@mui/material';

type Props = {
    grade: StudyEvaluationGrade;
}

const shrinkAnimation = keyframes`
  0% {
    transform: scale(1.3) translateX(-30%);
  }
  100% {
    transform: scale(1) translateX(0);
  }
`;

const EvaluationGradeContainer = styled(Box)`
  animation: ${shrinkAnimation} 1s ease-in-out forwards;
  transform-origin: center center; // Dodaj tę linijkę
  display: grid;
  flex-direction: column;
  grid-template-columns: 1fr 1fr;
  width: 280px;
  align-self: flex-end;
  margin-left: auto;
  overflow: visible;
`;

const GradeTextContainer = styled(Box)`
  display: flex;
  align-items: center;
`;

const getGradeAnimation = (grade: StudyEvaluationGrade) => {
  switch(grade) {
  case StudyEvaluationGrade.GREAT:
    return <Lottie animationData={Heart} loop={false}></Lottie>;
  case StudyEvaluationGrade.NOT_BAD:
    return <Lottie animationData={Thumb} loop={false}></Lottie>;
  case StudyEvaluationGrade.REPEAT:
    return <Lottie animationData={Repeat} loop={false}></Lottie>;
  default:
    return null;
  }
};

const getGradeText = (grade: StudyEvaluationGrade) => {
  switch (grade) {
  case StudyEvaluationGrade.GREAT:
    return 'Super! Umiesz to!';
  case StudyEvaluationGrade.NOT_BAD:
    return 'Nieźle, ale jeszcze powtórzmy';
  case StudyEvaluationGrade.REPEAT:
    return 'Wróćmy do tego później';
  default:
    return null;
  }
};

export const StudentGrade = ({ grade }: Props) => {
  return (
    <EvaluationGradeContainer>
      { getGradeAnimation(grade) }
      <GradeTextContainer>
        <Typography variant="button" style={{ overflow: 'visible' }}>{ getGradeText(grade) }</Typography>
      </GradeTextContainer>
    </EvaluationGradeContainer>
  );
};
