export enum ActionKind {
  ADD_CONTENT_ITEM = 'ADD_CONTENT_ITEM',
  UPDATE_CONTENT_ITEM = 'UPDATE_CONTENT_ITEM',
  REMOVE_CONTENT_ITEM = 'REMOVE_CONTENT_ITEM',
  CHANGE_LESSON = 'CHANGE_LESSON',
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_EDIT_MODE = 'SET_EDIT_MODE',
  SET_IS_EDITOR_READY = 'SET_IS_EDITOR_READY',
  CLEAN_CONTENT = 'CLEAN_CONTENT',
  REORDER_CONTENT = 'REORDER_CONTENT',
  CHANGE_STATUS = 'CHANGE_STATUS',
  UPDATE_SETTINGS = 'UPDATE_SETTINGS',
  SET_TAGS = 'SET_TAGS',
  SET_STUDY_SESSION_ID = 'SET_STUDY_SESSION_ID',
  SET_STUDY_SESSION_FILTERS = 'SET_STUDY_SESSION_FILTERS',
  SET_PRESENTATION_SESSION_ID = 'SET_PRESENTATION_SESSION_ID'
}
