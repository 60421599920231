import React, { memo } from 'react';
import { Box, Button, Grid, Modal, Stack, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useSocket } from '../../../hooks/socket';
import style from '../../LessonEditor/modals/style';
import { useEffectOnce } from 'usehooks-ts';
import { StudySessionStats } from '../../../types';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { getStats, getUserRole, studySessionState } from '../../../recoil/study';
import { useAuth } from '../../../hooks/auth';
import { FormattedMessage } from 'react-intl';
import { StudyEvaluationRole } from '../../../enums';
import { useNavigate } from 'react-router-dom';

type Props = {
  sessionId?: string;
  onClose: () => void;
}

const ModalStack = styled(Stack)`
  max-width: 480px;
  min-width: 300px;
  margin: 16px auto 0;
  display: flex;
  text-align: center;
  align-items: center;
`;

const EvaluateStatsModal = memo(
  function EvaluateStatsModal({ sessionId, onClose }: Props) {
    const { socket } = useSocket();
    const { user } = useAuth();
    const navigate = useNavigate();
    const setStudySessionState = useSetRecoilState(studySessionState);
    const sessionStats = useRecoilValue(getStats);
    const role = useRecoilValue(getUserRole(user?._id));

    const headerPath = role === StudyEvaluationRole.EVALUATOR ? 'components.lesson.study.stats.evaluatorHeader' : 'components.lesson.study.stats.learnerHeader';

    useEffectOnce(() => {
      socket.emit('study:stats', { sessionId }, (err: unknown, data: StudySessionStats) => {
        if(err) {
          console.error(err);
          return;
        }
        setStudySessionState((prevState) => {
          if(prevState) {
            return ({
              ...prevState,
              stats: data,
            });
          }
          return prevState;
        });
      });
    });

    const fetchStats = () => {
      socket.emit('study:stats', { sessionId }, (err: unknown, data: StudySessionStats) => {
        if(err) {
          console.error(err);
          return;
        }
        setStudySessionState((prevState) => {
          if(prevState) {
            return ({
              ...prevState,
              stats: data,
            });
          }
          return prevState;
        });
      });
    };

    const handleSessionClose = () => {
      onClose();
      navigate('/');
    };

    return (
      <Modal
        open
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ModalStack spacing={2}>
            <Typography variant='h6'>
              <FormattedMessage id={headerPath} values={{ name: user?.name }}/>
            </Typography>
            <br />
            {
              !sessionStats?.average && (
                <Button size="large" variant="contained" onClick={fetchStats}>Pobierz ponownie</Button>
              )
            }
            <Typography variant='button'>
              <FormattedMessage id='components.lesson.study.stats.average' values={{ average: sessionStats?.average }}/>
            </Typography>
            <Grid container spacing={2} width="320px">
              <Grid item xs={10} textAlign='left'>
                <Typography variant='subtitle2'>
                  <FormattedMessage id='components.lesson.study.stats.great'/>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant='subtitle2'>
                  { sessionStats?.count.great }
                </Typography>
              </Grid>
              <Grid item xs={10} textAlign='left'>
                <Typography variant='subtitle2'>
                  <FormattedMessage id='components.lesson.study.stats.notBad'/>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant='subtitle2'>
                  { sessionStats?.count.notBad }
                </Typography>
              </Grid>
              <Grid item xs={10} textAlign='left'>
                <Typography variant='subtitle2'>
                  <FormattedMessage id='components.lesson.study.stats.repeat'/>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant='subtitle2'>
                  { sessionStats?.count.repeat }
                </Typography>
              </Grid>
              <Grid item xs={10} textAlign='left'>
                <Typography variant='body2'>
                  <FormattedMessage id='components.lesson.study.stats.skipped'/>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant='body2'>
                  { sessionStats?.count.skipped }
                </Typography>
              </Grid>
            </Grid>
            <Button onClick={handleSessionClose}>
              <FormattedMessage id='components.lesson.study.stats.button'/>
            </Button>
          </ModalStack>
        </Box>
      </Modal>
    );
  }
);

export default EvaluateStatsModal;
