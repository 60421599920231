import React from 'react';
import { Stack, styled } from '@mui/material';
import LessonViewerContentItem from './LessonViewerContentItem';
import { Lesson } from '../../types';

const ContentEditorsBox = styled(Stack)<{ fullHeight?: boolean }>`
  overflow-y: ${({ fullHeight }) => fullHeight ? 'hidden' : 'scroll' };
  overflow-x: hidden;
  height: ${({ fullHeight }) => fullHeight ? '100%' : 'calc(100vh - 144px);' }
  margin-left: 4px;
  margin-top: 4px;
  
  @media print {
    height: 100% !important;
    overflow: visible !important;
  }
`;

type Props = {
  lesson: Lesson;
}

const LessonContentEditors = ({ lesson }: Props) => {
  return (
    <ContentEditorsBox fullHeight={true}>
      {
        lesson?.content?.data.map((item) => (
          <LessonViewerContentItem
            key={item.id}
            item={item}
          />
        ))
      }
    </ContentEditorsBox>
  );
};

export default LessonContentEditors;
