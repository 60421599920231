import { kebabCase } from 'lodash';
import { Lesson } from './types';

export const reorder = <T>(list: Array<T>, startIndex: number, endIndex: number): Array<T> => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getLessonPath = (lesson: Lesson) => {
  return `/lesson/${kebabCase(lesson.header)}/${lesson.shortId}`;
};

export const getSocketToken = () => {
  const token = localStorage.getItem('socket:token');

  if (!token) {
    const randomToken = Math.random().toString(36).substring(7);
    localStorage.setItem('socket:token', randomToken);
    return randomToken;
  }

  return token;
};