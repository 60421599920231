import React, { memo, useEffect, useState } from 'react';
import { Box, Button, CircularProgress, IconButton, Link, Modal, Stack, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';
import ApiService from '../../../services/ApiService';
import useSWR, { useSWRConfig } from 'swr';
import { LessonShare, Result } from '../../../types';
import { kebabCase } from 'lodash';
import { useCopyToClipboard, useEffectOnce } from 'usehooks-ts';
import { toast } from 'react-hot-toast';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import style from './style';
import ReactGA from 'react-ga4';

type Props = {
  lessonId?: string;
  onClose: () => void;
}

const FormStack = styled(Stack)`
  max-width: 480px;
  min-width: 320px;
  margin: 16px auto 0;
  display: flex;
  text-align: center;
`;

const getUrl = (data: Result<LessonShare> | undefined) => {
  if(data && data.statusCode === 200 && data.data) {
    const host = window.location.protocol + "//" + window.location.host;
    return `${host}/lesson/${kebabCase(data.data.slug)}/${data.data.token}/share`;
  }
  return null;
};

const fetcher = (lessonId: string | undefined) => () => ApiService.getShareLessonData(lessonId);

const LessonShareModal = memo(
  function LessonShareModal({ lessonId, onClose }: Props) {
    const { data, isLoading, error } = useSWR<Result<LessonShare>, Error>(`/lessons/${lessonId}/share`, fetcher(lessonId));
    const { mutate } = useSWRConfig();
    const [showInfo, setShowInfo] = useState<boolean>(false);
    const url = getUrl(data);
    const [, copy] = useCopyToClipboard();

    useEffectOnce(() => {
      ReactGA.event({
        category: 'editor',
        action: 'openShareLessonModal',
      });
    });

    useEffect(() => {
      if(data?.statusCode === 404) {
        ApiService.shareLesson(lessonId).then(() => {
          mutate(`/lessons/${lessonId}/share`);
        });
      }
    }, [data, lessonId, mutate]);

    const handleCopy = async () => {
      if(url) {
        ReactGA.event({
          category: 'editor',
          action: 'copyShareLessonLink',
        });

        await copy(url);
        onClose();
        toast.success('Link został skopiowany do schowka');
      }
    };

    const handleInfoClick = () => {
      setShowInfo((prevInfo) => !prevInfo);
    };

    return (
      <Modal
        open
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <FormStack spacing={2}>
            { isLoading &&
              <CircularProgress sx={{ alignSelf: 'center' }} />
            }
            {
              (!isLoading && (error || !data)) &&
              <Typography variant='subtitle2'>Coś poszło nie tak, spróbuj ponownie</Typography>
            }
            { url && !showInfo &&
              <>
                <Button onClick={handleCopy} variant='contained' sx={{ }}
                  type='submit'>
                  <FormattedMessage id='components.lesson.share.copyToClipboard' />
                </Button>
                <Link underline="none" onClick={handleInfoClick}>
                  <FormattedMessage id='components.lesson.share.label' />
                </Link>
              </>
            }
            { showInfo &&
              <>
                <IconButton onClick={handleInfoClick} sx={{ width: '48px' }}>
                  <ArrowBackIcon />
                </IconButton>
                <Typography gutterBottom>
                  <FormattedMessage id='components.lesson.share.info1' />
                  { ' ' }<br />
                  <FormattedMessage id='components.lesson.share.info2' />
                </Typography>
                <Typography>
                  <FormattedMessage id='components.lesson.share.info3' />
                </Typography>
              </>
            }
          </FormStack>
        </Box>
      </Modal>
    );
  }
);

export default LessonShareModal;
