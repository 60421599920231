import { useForm, SubmitHandler } from 'react-hook-form';
import { Button, Stack } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { resetPasswordFormSchema } from './validationSchemas';
import FormInputField from '../components/form/FormInputField';
import ApiService from '../services/ApiService';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useTranslation } from '../hooks/utils';

interface FormInput {
  password: string;
  repeatedPassword: boolean;
}

const FormStack = styled(Stack)`
  max-width: 400px;
  margin: 40px auto 0;
  display: flex;
  align-items: center
`;

const ResetPasswordForm = ({ token }: { token: string }) => {
  const { control, handleSubmit, formState: { errors } } = useForm<FormInput>({
    resolver: yupResolver(resetPasswordFormSchema),
  });
  const navigate = useNavigate();
  const translation = useTranslation();

  const onSubmit: SubmitHandler<FormInput> = async data => {
    await ApiService.auth.resetPassword({
      token,
      password: data.password,
    });
    navigate('/login');
    toast.success(translation( 'toasts.resetPasswordChangesSuccessfully'));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormStack spacing={3}>
        <FormInputField<FormInput>
          {...{ control, errors }}
          name='password'
          defaultValue=''
          type='password'
          labelPath='forms.resetPassword.password'
        />
        <FormInputField<FormInput>
          {...{ control, errors }}
          name='repeatedPassword'
          defaultValue=''
          type={'password'}
          labelPath='forms.resetPassword.repeatedPassword'
        />
        <Button variant='contained' sx={{ maxWidth: 200 }} type='submit'>
          <FormattedMessage id='forms.resetPassword.button' />
        </Button>
      </FormStack>
    </form>
  );
};

export default ResetPasswordForm;
