import { Outlet } from 'react-router-dom';
import { useSocket } from '../hooks/socket';
import { useEffectOnce } from 'usehooks-ts';

const SocketUsageWrapper = () => {
  const { socket } = useSocket();

  useEffectOnce(() => {
    setTimeout(() => {
      socket.connect();
    }, 500);

    // socket.on('client-error', () => {
    //   // Hacky fix that
    //   setTimeout(async () => {
    //     if (socket.io.engine) {
    //       await socket.io.engine.close();
    //       socket.connect();
    //     }
    //   }, 1000);
    // });

    return () => {
      socket.disconnect();
    };
  });

  return (
    <div>
      <Outlet />
    </div>
  );
};

export default SocketUsageWrapper;
