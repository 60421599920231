import ReactQuill from 'react-quill';
import { DeltaStatic } from 'quill';
import { memo, useEffect, useRef } from 'react';
import { styled } from '@mui/material';

type Props = {
  value?: string;
  onChange: (value: string, delta: DeltaStatic) => void
  handleFocus: (editor: ReactQuill | null, id: string | null) => void
  id: string;
  readOnly?: boolean;
  hiddenMode?: boolean;
  fontSize?: number;
}

type ContainerProps = {
  fontSize: number;
}

const SingleEditorContainer = styled('div')<ContainerProps>`
  height: 100%;
  
  .ql-container {
    font-size: ${(props) => props.fontSize}px;
  }
`;

const ViewerSingleEditor = memo(({ value, id, fontSize }: Props) => {
  const editorRef = useRef<ReactQuill | null>(null);

  useEffect(() => {
    if(value) {
      editorRef.current?.editor?.setContents(JSON.parse(value) as DeltaStatic);
    }
  }, [value]);

  return (
    <SingleEditorContainer fontSize={fontSize ?? 16}>
      <ReactQuill
        id={id}
        theme='snow'
        style={{ height: '100%', maxWidth: '100%' }}
        onFocus={() => {}}
        ref={editorRef}
        readOnly={true}
        modules={{
          toolbar: {
            container: `#toolbar`,
          },
          keyboard: {
            bindings: {
              tab: false,
            }
          }
        }}
        formats={['bold', 'italic', 'strike', 'underline', 'link', 'image', 'video']}
      />
    </SingleEditorContainer>);
});

export default ViewerSingleEditor;
