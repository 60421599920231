import {
  Box, Button, IconButton, Menu, MenuItem, styled,
  ToggleButton, ToggleButtonGroup, Tooltip, Typography, useMediaQuery,
} from '@mui/material';
import Toolbar from './Toolbar';
import React, { ReactNode, useEffect, useState } from 'react';
import { useModal } from 'react-modal-hook';
import LessonJournalModal from './modals/LessonJournalModal/LessonJournalModal';
import { useEditor } from '../../hooks/editor';
import { EditMode, StudyEvaluationGrade } from '../../enums';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useAuth } from '../../hooks/auth';
import { useTranslation } from '../../hooks/utils';
import LessonShareModal from './modals/LessonShareModal';
import LessonTagsModal from './modals/LessonTagsModal';
import RemoveLessonModal from './modals/RemoveLessonModal';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import LessonStudyInitModal from './modals/LessonStudyInitModal';
import ReactGA from 'react-ga4';
import { CircularProgressWithLabel } from '../LessonsList/CircularProgressWithLabel';
import { useFlag } from '@unleash/proxy-client-react';
import ApiService from '../../services/ApiService';
import { LessonStudySession } from '../../types';
import useSWR from 'swr';
import { useSocket } from '../../hooks/socket';
import { useEffectOnce } from 'usehooks-ts';

const fetcher = (url: string) => ApiService.get<LessonStudySession[]>(url);

const LessonEditorToolbarBox = styled(Box)`
  padding: 0 4px;
  height: 56px;
  background-color: #fff;
  display: flex;
  align-items: center;
`;

const LessonLearnToolbarBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
  padding-right: 8px;
`;

const LessonOptionsBox = styled(Box)`
  margin-left: auto;
`;

const ToggleButtonStyled = styled(ToggleButton)<{ borderColor: string }>`
    border: 1px solid ${props => props.borderColor};
`;

const LessonEditMode = () => {
  const { state: { editMode, presentationSessionId }, setEditMode } = useEditor();
  const { socket } = useSocket();
  const translation = useTranslation();

  const handleChange = (value: EditMode) => {
    setEditMode(value);
  };

  return (
    <Box sx={{ display: 'inline-flex' }}>
      {
        editMode === EditMode.READ_ONLY && (
          <Button
            variant='contained'
            size='small'
            onClick={() => handleChange(EditMode.TEXT_EDIT)}
          >
            {translation('components.lesson.edit')}
          </Button>
        )
      }
      {
        [EditMode.TEXT_EDIT, EditMode.CONTENT_EDIT, EditMode.STUDY_HISTORY].includes(editMode) && (
          <Button
            variant='contained'
            size='small'
            onClick={() => handleChange(EditMode.READ_ONLY)}
          >
            {translation('components.lesson.exit')}
          </Button>
        )
      }
      {
        [EditMode.CONTENT_PRESENTATION].includes(editMode) && (
          <Button
            variant='contained'
            size='small'
            onClick={() => {
              socket.emit('presentation:finish', { sessionId: presentationSessionId });
              handleChange(EditMode.READ_ONLY);
            }}
          >
            {translation('components.lesson.finish')}
          </Button>
        )
      }
      {
        [EditMode.TEXT_EDIT].includes(editMode) && (
          <Button
            variant='text'
            size='small'
            onClick={() => handleChange(EditMode.CONTENT_EDIT)}
            sx={{ ml: 1 }}
          >
            {translation('components.lesson.fields')}
          </Button>
        )
      }
      {
        [EditMode.CONTENT_EDIT].includes(editMode) && (
          <Button
            variant='text'
            size='small'
            onClick={() => handleChange(EditMode.TEXT_EDIT)}
            sx={{ ml: 1 }}
          >
            {translation('components.lesson.text')}
          </Button>
        )
      }
    </Box>
  );
};

type MenuWithVerticalIconProps = {
    id: string;
    children: ReactNode | ReactNode[];
};

const MenuWithVerticalIcon = ({ id, children }: MenuWithVerticalIconProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const isOpen = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label='more'
        id={`${id}-button`}
        aria-controls={isOpen ? id : undefined}
        aria-expanded={isOpen ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleMenuOpen}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={id}
        MenuListProps={{
          'aria-labelledby': id,
        }}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleMenuClose}
      >
        {children}
      </Menu>
    </>
  );
};

const LessonOptions = () => {
  const { state, setTags, setEditMode, setPresentationSessionId } = useEditor();
  const { socket } = useSocket();
  const { helpers } = useAuth();
  const translation = useTranslation();
  const mobileMatch = useMediaQuery('(max-width:480px)');
  const canShare = !['view', 'edit'].includes(state.lesson?.permission || '');
  const [watchers, setWatchers] = useState<number>(0);

  useEffectOnce(() => {
    socket.on('presentation:joined', (data: { students: number }) => {
      setWatchers(data.students);
    });
  });

  const handleTagsSave = (tags: string[]) => {
    setTags(tags);
  };

  const [showModal, hideModal] = useModal(() => <LessonJournalModal lessonId={state.lesson?._id}
    onClose={hideModal} />, [state.lesson?._id]);
  const [showShareModal, hideShareModal] = useModal(() => <LessonShareModal lessonId={state.lesson?._id}
    onClose={hideShareModal} />, [state.lesson?._id]);
  const [showTagsModal, hideTagsModal] = useModal(() =>
    <LessonTagsModal
      lessonId={state.lesson?._id}
      tags={state.lesson?.tags}
      onClose={hideTagsModal}
      onSave={handleTagsSave}
    />, [state.lesson?.tags, state.lesson?._id]);
  const [showRemoveModal, hideRemoveModal] = useModal(() => <RemoveLessonModal lessonId={state.lesson?._id}
    onClose={hideRemoveModal} />, [state.lesson?._id]);
  const [showLearnModal, hideLearnModal] = useModal(() => <LessonStudyInitModal lessonId={state.lesson?._id}
    onClose={hideLearnModal} onSetEditMode={setEditMode} onSetSessionId={setPresentationSessionId}  />, [state.lesson?._id]);

  const handleShowLearnModal = () => {
    ReactGA.event({
      category: 'editor',
      action: 'openStudyModal',
    });

    showLearnModal();
  };

  if (mobileMatch) {
    return (
      <LessonOptionsBox>
        {state.editMode === EditMode.READ_ONLY &&
                <Button variant='contained' size='small'
                  onClick={handleShowLearnModal}>{translation('components.lesson.learning')}</Button>
        }
        <MenuWithVerticalIcon id='lesson-menu'>
          {[EditMode.TEXT_EDIT, EditMode.CONTENT_EDIT].includes(state.editMode) &&
                    <MenuItem onClick={handleShowLearnModal}>{translation('components.lesson.learning')}</MenuItem>
          }
          <MenuItem onClick={showShareModal}>{translation('components.lesson.share.button')}</MenuItem>
          {
            helpers.isTeacher && state.editMode === EditMode.READ_ONLY &&
                    <MenuItem onClick={showModal}>{translation('components.lesson.journal')}</MenuItem>
          }
          {state.editMode === EditMode.TEXT_EDIT &&
                    <>
                      <MenuItem onClick={showTagsModal}>{translation('components.lesson.tags.button')}</MenuItem>
                      <MenuItem onClick={showRemoveModal} color='error'>
                        <Typography color='error'>{translation('components.lesson.remove.button')}</Typography>
                      </MenuItem>
                    </>
          }
        </MenuWithVerticalIcon>
      </LessonOptionsBox>
    );
  }

  return (
    <LessonOptionsBox>
      {[EditMode.CONTENT_EDIT, EditMode.TEXT_EDIT, EditMode.READ_ONLY].includes(state.editMode) &&
            <Button variant='contained' size='small'
              onClick={showLearnModal}>{translation('components.lesson.learning')}</Button>
      }
      {[EditMode.CONTENT_EDIT, EditMode.TEXT_EDIT, EditMode.READ_ONLY].includes(state.editMode) &&
            <Tooltip arrow
              title={canShare ? 'Kliknij aby udostępnić' : 'Autor notatki nie zezwolił na jej dalsze udostępnianie'}>
              <span>
                <Button disabled={!canShare}
                  onClick={showShareModal}>{translation('components.lesson.share.button')}</Button>
              </span>
            </Tooltip>
      }
      {
        helpers.isTeacher && state.editMode === EditMode.READ_ONLY &&
            <Button onClick={showModal}>{translation('components.lesson.journal')}</Button>
      }
      {
        state.editMode === EditMode.CONTENT_PRESENTATION &&
        <>
          <Typography variant='subtitle2' mr={1} color='textSecondary'>{translation('components.lesson.watchers')}: {watchers}</Typography>
        </>
      }
      {
        state.editMode === EditMode.TEXT_EDIT &&
            <MenuWithVerticalIcon id='lesson-menu'>
              <MenuItem onClick={showTagsModal}>{translation('components.lesson.tags.button')}</MenuItem>
              <MenuItem onClick={showRemoveModal}>
                <Typography color='error'>{translation('components.lesson.remove.button')}</Typography>
              </MenuItem>
            </MenuWithVerticalIcon>
      }
    </LessonOptionsBox>
  );
};

const LessonLearnPanel = () => {
  const { state, setEditMode, setStudySessionId, setStudySessionFilters } = useEditor();
  const apiUrl = `/lessons/${state.lesson?._id}/study/list`;
  const isHistoryMode = state.editMode === EditMode.STUDY_HISTORY;

  const { data: lessonStudySessionsResponse } = useSWR(isHistoryMode ? apiUrl : null, fetcher);

  useEffect(() => {
    if (lessonStudySessionsResponse?.statusCode === 200) {
      if(lessonStudySessionsResponse?.data[0]?.id) {
        setStudySessionId(lessonStudySessionsResponse?.data[0]?.id);
      }
    }
  }, [lessonStudySessionsResponse]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event: React.MouseEvent<HTMLElement>, filters: StudyEvaluationGrade[]) => {
    setStudySessionFilters(filters);
  };

  return (
    <>
      {
        state.editMode === EditMode.READ_ONLY && state.lesson?.stats?.average &&
            <Box ml={1} mt='4px'>
              <CircularProgressWithLabel
                variant='determinate'
                value={state.lesson?.stats?.average}
                selected={false}
                size={36}
                onClick={() => setEditMode(EditMode.STUDY_HISTORY)}
              />
            </Box>
      }
      {isHistoryMode &&
            <LessonLearnToolbarBox>
              <Box sx={{ display: 'inline-flex' }} >
                {lessonStudySessionsResponse?.statusCode === 200 && lessonStudySessionsResponse.data.map((session) => (
                  <Box key={session?.id} ml='4px' mt='4px'>
                    <CircularProgressWithLabel
                      variant='determinate'
                      value={session.stats.grade}
                      selected={!(session?.id === state.studySessionId)}
                      date={session.createdAt}
                      size={36}
                      onClick={() => {
                        ReactGA.event({
                          category: 'editor',
                          action: 'openHistoryMode',
                        });

                        setStudySessionId(session.id);
                      }}
                    />
                  </Box>
                ))
                }
              </Box>
              <ToggleButtonGroup
                color="primary"
                value={state.studySessionFilters}
                onChange={handleChange}
                aria-label='Filter study history'
              >
                <ToggleButtonStyled color="success" borderColor="green" value={StudyEvaluationGrade.GREAT}>❤️</ToggleButtonStyled>
                <ToggleButtonStyled color="warning" borderColor="orange" value={StudyEvaluationGrade.NOT_BAD}>👍</ToggleButtonStyled>
                <ToggleButtonStyled color="error" borderColor="red" value={StudyEvaluationGrade.REPEAT}>😏</ToggleButtonStyled>
              </ToggleButtonGroup>
            </LessonLearnToolbarBox>
      }
    </>
  );
};

export const LessonEditorToolbar = () => {
  const { state } = useEditor();
  const mobileMatch = useMediaQuery('(max-width: 900px)');
  const navigate = useNavigate();
  const isStudyHistoryEnabled = useFlag('study-history');

  return (
    <LessonEditorToolbarBox>
      {
        state.editMode === EditMode.READ_ONLY && mobileMatch &&
            <IconButton onClick={() => navigate('/')}>
              <ArrowBackIcon />
            </IconButton>
      }
      <LessonEditMode />
      {isStudyHistoryEnabled &&
            <LessonLearnPanel />
      }
      <Toolbar />
      <LessonOptions />
    </LessonEditorToolbarBox>
  );
};
