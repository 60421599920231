import { Box, Paper, Typography } from '@mui/material';
import styled from '@emotion/styled';

const SubheaderBox = styled(Box)`
  height: 48px;
  display: flex;
  padding: 0 8px;
  background-color: #eee;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

type Props = {
  header?: string | null;
};

const PresentationSubheader = ({ header }: Props) => {
  return (
    <Paper elevation={1}>
      <SubheaderBox>
        <Typography variant='h6' sx={{ fontWeight: 600, marginTop: '4px' }}>
          {header}
        </Typography>
      </SubheaderBox>
    </Paper>
  );
};

export default PresentationSubheader;
