import { memo } from 'react';
import styled from '@emotion/styled';
import { Box, Button, Modal, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import ApiService from '../../../services/ApiService';
import { toast } from 'react-hot-toast';
import { useTranslation } from '../../../hooks/utils';
import { useNavigate } from 'react-router-dom';
import style from './style';

type Props = {
  lessonId: string | undefined;
  onClose: () => void;
};

const ButtonsBox = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

const RemoveLessonModal = memo(
  function RemoveLessonModal({ lessonId, onClose }: Props) {
    const translation = useTranslation();
    const navigate = useNavigate();

    const handleConfirm = async () => {
      if(lessonId) {
        await ApiService.removeLesson(lessonId);
        setTimeout(() => {
          toast.success(translation( 'toasts.removeLessonSuccessful'));
          navigate('/');
          onClose();
        }, 1000);
      }
    };

    return (
      <Modal
        open
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h6" sx={{ marginBottom: 3 }}>
            <FormattedMessage id='components.lesson.remove.header' />
          </Typography>
          <Typography id="modal-modal-title" variant="subtitle1" sx={{ marginBottom: 3 }}>
            <FormattedMessage id='components.lesson.remove.confirmation' />
          </Typography>
          <ButtonsBox>
            <Button onClick={onClose}>Anuluj</Button>
            <Button variant="contained" color='primary' onClick={handleConfirm}>Tak, usuń notatkę</Button>
          </ButtonsBox>
        </Box>
      </Modal>
    );
  }
);

export default RemoveLessonModal;
