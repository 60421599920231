import React from 'react';
import { useParams } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import SharedLessonViewer from '../components/SharedLesson/SharedLessonViewer';
import useSWR from 'swr';
import ApiService from '../services/ApiService';
import { Lesson, Result } from '../types';

const fetcher = (url: string) => ApiService.get<Lesson>(url);

const SharedLessonPage = () => {
  const params = useParams();

  const { data, isLoading } = useSWR<Result<Lesson>, Error>(`/lessons/public/${params.token}`, fetcher, {
    revalidateOnFocus: false,
  });

  if (!params.token || (data?.statusCode !== 200)) {
    return null;
  }

  return (
    <SharedLessonViewer lesson={data.data} token={params.token} isLoading={isLoading} />
  );
};

export default SharedLessonPage;
