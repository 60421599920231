import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, InputBase, InputLabel, Skeleton, styled, Typography } from '@mui/material';
import { useSocket } from '../../hooks/socket';
import { useEditor } from '../../hooks/editor';
import { useTranslation } from '../../hooks/utils';
import { EditMode } from '../../enums';

const LessonHeaderBox = styled(Box)`
  height: 36px;
  background-color: #efefef;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 4px;
  padding-left: 12px;
`;

const LessonHeaderInputBox = styled(Box)`
  display: inline-flex;
  align-items: baseline;
  flex-grow: 1;
`;

const HeaderInputField = styled(InputBase)`
  flex-grow: 1;
`;

const LessonEditorProgressBox = styled(Box)`
  display: inline-flex;
  align-items: baseline;
  padding: 4px 4px 0 4px;
  margin-right: 8px;
`;

type ContainerProps = {
  fontSize: number
}

const LessonHeaderInputLabel = styled(InputLabel)<ContainerProps>`
  font-size: ${(props) => props.fontSize}px;
`;

const getLessonHeaderInputSize = (fontSize: number) => {
  if(fontSize <= 12) {
    return 'small';
  }
  if(fontSize > 12) {
    return 'medium';
  }
  return 'small';
};

const LessonHeaderInput = () => {
  const { socket } = useSocket();
  const { state: { status, settings: { fontSize }, lesson, editMode, isLoading }, setStatus } = useEditor();
  const translation = useTranslation();
  const disabled = editMode !== EditMode.TEXT_EDIT;
  const [header, setHeader] = useState<string>(lesson?.header ?? '');

  useEffect(() => {
    setHeader(lesson?.header ?? '');
  }, [lesson?.header]);

  const handleHeaderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHeader(event.target.value);

    setStatus({
      isSaving: true,
      lastSaved: null,
    });

    if(lesson) {
      socket.emit('update-lesson-meta', {
        lessonId: lesson._id,
        header: event.target.value,
      });
    }
  };

  return (
    <LessonHeaderBox>
      <LessonHeaderInputBox>
        <LessonHeaderInputLabel htmlFor="header" fontSize={fontSize}>
          {translation('components.lesson.subject')}:
        </LessonHeaderInputLabel>
        {
          isLoading && <Skeleton sx={{ marginLeft: 1 }} animation='wave' height={24} width={120}/>
        }
        {
          !isLoading &&
            <HeaderInputField
              id="header"
              size={getLessonHeaderInputSize(fontSize)}
              sx={{ marginLeft: 1, fontSize }}
              onChange={handleHeaderChange}
              disabled={disabled}
              value={header}
            />
        }
      </LessonHeaderInputBox>
      <LessonEditorProgressBox>
        { !status.connectionError && status.lastSaved &&
          <Typography sx={{ marginRight: '8px' }} variant="body2">
            {translation('components.lesson.saved')}
          </Typography>
        }
        { status.connectionError &&
          <Typography sx={{ marginRight: '8px' }} variant="body2" color='red'>
            {translation('components.lesson.error')}
          </Typography>
        }
        { status.isSaving && <CircularProgress size={16} /> }
      </LessonEditorProgressBox>
    </LessonHeaderBox>
  );
};

export default LessonHeaderInput;
