import { LessonContentItem } from '../../types';
import { Box,  Paper, styled } from '@mui/material';
import React from 'react';
import ViewerSingleEditor from './ViewerSingleEditor';

type DraggableContentItemProps = {
  item: LessonContentItem;
};

const ContentItemBox = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-auto-rows: min-content;
  gap: 8px;
  padding: 4px;
`;

const LessonViewerContentItem = ({ item }: DraggableContentItemProps) => {
  const Editors = (
    <>
      <Paper elevation={1}>
        <ViewerSingleEditor
          readOnly={true}
          value={item.header.delta}
          onChange={() => {}}
          id={`h-${item.id}`}
          handleFocus={() => {}}
        />
      </Paper>
      <Paper elevation={1}>
        <ViewerSingleEditor
          readOnly={true}
          value={item.paragraph.delta}
          onChange={() => {}}
          id={`p-${item.id}`}
          handleFocus={() => {}}
        />
      </Paper>
    </>
  );

  return (
    <ContentItemBox
      key={item.id}
    >
      { Editors }
    </ContentItemBox>
  );
};


export default LessonViewerContentItem;
