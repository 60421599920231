import { EditMode } from '../../enums';
import { createContext, ReactNode, useEffect, useReducer, useRef, useState } from 'react';
import { EditorsContext } from './types';
import { Lesson } from '../../types';
import { getInitialState, reducer } from './reducer';
import ReactQuill from 'react-quill';
import { useSocket } from '../socket';
import { useTranslation } from '../utils';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLessons } from '../lessons';
import { ActionKind } from './enums';
import { toast } from 'react-hot-toast';

const initialContext = {
  state: getInitialState(),
  dispatch: () => null,
  editors: new Map(),
  currentEditor: { current: null },
  currentEditorId: null,
  setCurrentEditorId: () => null,
};

export const editorsContext = createContext<EditorsContext>(initialContext);

export function ProvideEditor({ children, lessonId }: { children: ReactNode | ReactNode[], lessonId: string }) {
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const [editors] = useState(new Map());
  const currentEditor = useRef<ReactQuill | null>(null);
  const [currentEditorId, setCurrentEditorId] = useState<string | null>(null);
  const { socket, socketStatus } = useSocket();
  const translation = useTranslation();
  const navigate = useNavigate();
  const { refetch } = useLessons();
  const [searchParams] = useSearchParams();
  const initialMode = searchParams.get('initialMode') as EditMode;

  useEffect(() => {
    dispatch({
      type: ActionKind.CLEAN_CONTENT,
    });
    dispatch({
      type: ActionKind.SET_IS_LOADING,
      payload: true,
    });

    socket.emit('lesson-session', {
      lessonId,
      options: {
        meta: {},
      },
    }, (err: string, lesson: { lessonId: string, lessonData: Lesson }) => {
      dispatch({
        type: ActionKind.CHANGE_LESSON,
        payload: lesson.lessonData,
      });
      dispatch({
        type: ActionKind.SET_IS_LOADING,
        payload: false,
      });
      dispatch({
        type: ActionKind.SET_IS_EDITOR_READY,
        payload: true,
      });
    });
  }, [lessonId, socket, socketStatus]);

  useEffect(() => {
    if(['TEXT_EDIT'].includes(initialMode) && state?.lesson) {
      setTimeout(() => {
        dispatch({
          type: ActionKind.SET_EDIT_MODE,
          payload: {
            editMode: initialMode,
          }
        });

        const editorsIterator = editors.values();
        const { value } = editorsIterator.next();

        const editor = value.current as ReactQuill;

        if (editor) {
          setTimeout(() => {
            const length = editor.unprivilegedEditor?.getLength();
            const selection = { index: length || 0, length: length || 0 };
            editor.getEditor().setSelection(selection);
          }, 250);
        }
      }, 500);
    }
  }, [editors, initialMode, socket, state.lesson]);

  // Socket errors handling
  useEffect(() => {
    socket.on('client-error', (data: { statusCode: number }) => {
      if(data.statusCode === 401) {
        toast.error('Wystąpił niespodziewany problem, zaloguj się ponownie.', {
          id: 'client-error',
        });
        return navigate('/login');
      }
    });

    socket.on('lesson-saved', (data: { status: string }) => {
      dispatch({
        type: ActionKind.CHANGE_STATUS,
        payload: {
          isSaving: false,
          lastSaved: new Date(),
          connectionError: false,
          errorCounter: 0,
        }
      });

      if(data.status === 'LESSON_META_SAVED') {
        setTimeout(() => refetch(), 500);
      }
    });

    return () => {
      socket.off('lesson-saved');
      socket.off('client-error');
    };
  }, [navigate, refetch, socket, translation]);

  useEffect(() => {
    socket.on('lesson-not-saved', () => {
      if(state.status.errorCounter > 1) {
        toast.error('Wystąpił problem z zapisem treści.\n\n Ponów próbę wprowadzenia, odśwież stronę lub sprawdź swoje połączenie internetowe.', {
          duration: 6000,
          id: 'save-error'
        });
      }

      dispatch({
        type: ActionKind.CHANGE_STATUS,
        payload: {
          isSaving: false,
          lastSaved: new Date(),
          connectionError: true,
          errorCounter: state.status.errorCounter + 1,
        }
      });
    });

    return () => {
      socket.off('lesson-not-saved');
    };
  }, [socket, state.status.errorCounter]);

  const value = {
    state,
    dispatch,
    currentEditor,
    currentEditorId,
    editors,
    setCurrentEditorId,
  };

  return (
    <editorsContext.Provider value={value}>{children}</editorsContext.Provider>
  );
}
