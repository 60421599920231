import queryString from 'query-string';
import AuthService from './AuthService';
import {
  Lesson,
  LessonEvidence,
  LessonEvidenceInput,
  LessonShare,
  LessonStudySession,
  Response,
  UserSchoolGroups,
  UserSchools,
} from '../types';
import type {
  CreateLessonParams,
  GetLessonParams,
  GetLessonsParams,
  LessonMetaInput,
  UpdateUserProfileParams,
} from './types';
import { PaginationParams } from './types';

const getQuery = (params?: Record<string, unknown>) => {
  if(!params) {
    return '';
  }
  return queryString.stringify(params, { skipNull: true, skipEmptyString: true });
};

class ApiService {
  public auth: AuthService;

  private readonly apiUrl: string;

  constructor() {
    this.auth = new AuthService();
    this.apiUrl = process.env.REACT_APP_API_URL as string;
  }

  getLessons(params: GetLessonsParams) {
    return this.request(`/lessons?${getQuery(params)}`, {
      method: 'GET',
    }) as Response<Lesson[]>;
  }

  get<T>(url: string) {
    return this.request(url, {
      method: 'GET',
    }) as Response<T>;
  }

  getLesson({ shortId }: GetLessonParams) {
    return this.request(`/lessons/${shortId}`, {
      method: 'GET',
    }) as Response<Lesson>;
  }

  getSharedLesson(token: string | undefined) {
    return this.request(`/lessons/public/${token}`, {
      method: 'GET',
    }) as Response<Lesson>;
  }

  cloneSharedLesson(token: string | undefined) {
    return this.request(`/lessons/public/${token}/clone`, {
      method: 'POST',
    }) as Response<Lesson>;
  }

  getUserSchoolsList() {
    return this.request(`/user/schools`, {
      method: 'GET',
    }) as Response<UserSchools>;
  }

  getUserSchoolGroupsList(schoolId: string) {
    return this.request(`/user/schools/${schoolId}/groups`, {
      method: 'GET',
    }) as Response<UserSchoolGroups>;
  }

  getLessonEvidence(lessonId: string | undefined) {
    return this.request(`/lessons/${lessonId}/evidence`, {
      method: 'GET',
    }) as Response<LessonEvidence>;
  }

  getLessonStudySessions(lessonId: string | undefined, pagination?: PaginationParams) {
    return this.request(`/lessons/${lessonId}/study/list?${getQuery(pagination)}`, {
      method: 'GET',
    }) as Response<LessonStudySession[]>;
  }

  getLessonStudySession(lessonId: string, studySessionId: string) {
    return this.request(`/lessons/${lessonId}/study/${studySessionId}`, {
      method: 'GET',
    }) as Response<LessonStudySession>;
  }

  shareLesson(lessonId: string | undefined) {
    return this.request(`/lessons/${lessonId}/share`, {
      method: 'PUT',
      body: JSON.stringify({
        permission: 'EDIT'
      })
    }) as Response<unknown>;
  }

  getShareLessonData(lessonId: string | undefined) {
    return this.request(`/lessons/${lessonId}/share`, {
      method: 'GET',
    }) as Response<LessonShare>;
  }

  updateLessonEvidence(lessonId: string, input: LessonEvidenceInput) {
    return this.request(`/lessons/${lessonId}/evidence`, {
      method: 'PUT',
      body: JSON.stringify(input),
    }) as Response<unknown>;
  }

  updateLessonMeta(lessonId: string, input: LessonMetaInput) {
    return this.request(`/lessons/${lessonId}/meta`, {
      method: 'PATCH',
      body: JSON.stringify(input),
    }) as Response<unknown>;
  }

  removeLesson(lessonId: string) {
    return this.request(`/lessons/${lessonId}`, {
      method: 'DELETE',
    }) as Response<unknown>;
  }

  updateUserProfile({ email, name, password }: UpdateUserProfileParams) {
    return this.request(`/user`, {
      method: 'PATCH',
      body: JSON.stringify({
        name,
        email,
        password
      })
    }) as Response<unknown>;
  }

  updateUserAvatar(file: File) {
    const formData = new FormData();

    formData.append('avatar', file);

    return this.request(`/user/avatar`, {
      method: 'POST',
      headers: {},
      body: formData,
    }) as Response<{ url: string }>;
  }

  uploadImage(file: File) {
    const formData = new FormData();

    formData.append('image', file);

    return this.request(`/images`, {
      method: 'POST',
      headers: {},
      body: formData,
    }) as Response<{ url: string }>;
  }

  createLesson({ header, isPublic, type }: CreateLessonParams) {
    return this.request(`/lessons`, {
      method: 'POST',
      body: JSON.stringify({
        header,
        type,
        public: isPublic,
      })
    }) as Response<Lesson>;
  }

  private async request(path: string, options: RequestInit) {
    const result = await fetch(`${this.apiUrl}${path}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      ...options,
    });

    return result.json();
  }
}

export default new ApiService();
