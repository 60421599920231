import { selector, selectorFamily } from 'recoil';
import { studySessionEvaluationState, studySessionState } from './atom';
import { StudyEvaluationMode, StudyEvaluationRole } from '../../enums';

export { studySessionState } from './atom';

export const getFriendName = selectorFamily({
  key: 'StudySessionFriendName',
  get: (userId) => ({ get }) => {
    const state = get(studySessionState);

    if(state?.mode === StudyEvaluationMode.SINGLE) {
      return null;
    }
    if(state?.learner.id === userId) {
      if(state?.evaluator?.type === 'GUEST') {
        return null;
      }
      return state?.evaluator?.name;
    }

    if(state?.evaluator.id === userId) {
      return state?.learner.name;
    }

    return state?.learner.name;
  }
});

export const getUserRole = selectorFamily({
  key: 'StudySessionUserRole',
  get: (userId) => ({ get }) => {
    const state = get(studySessionState);

    if(state?.learner.id === userId) {
      return StudyEvaluationRole.LEARNER;
    }

    if(state?.evaluator.id === userId) {
      return StudyEvaluationRole.EVALUATOR;
    }

    if(state?.evaluator?.type === 'GUEST') {
      return StudyEvaluationRole.EVALUATOR;
    }
  }
});

export const getCurrentCursor = selector({
  key: 'StudySessionCurrentCursor',
  get: ({ get }) => {
    const state = get(studySessionState);

    if(state) {
      return state.cursor;
    }
    return null;
  }
});

export const getSessionMode = selector({
  key: 'GetSessionMode',
  get: ({ get }) => {
    const state = get(studySessionState);

    if(state) {
      return state.mode;
    }
    return null;
  }
});

export const getShowCard = selector({
  key: 'GetShowCard',
  get: ({ get }) => {
    const state = get(studySessionEvaluationState);

    if(state) {
      return state.showEvaluationBox;
    }
    
    return null;
  }
});

export const getCurrentParagraph = selector({
  key: 'StudySessionCurrentParagraph',
  get: ({ get }) => {
    const state = get(studySessionState);

    if(state) {
      return state.content[state.cursor.index];
    }
    return null;
  }
});

export const getCurrentParagraphEvaluation = selector({
  key: 'StudySessionCurrentParagraphEvaluation',
  get: ({ get }) => {
    const state = get(studySessionState);

    if(state) {
      return state.evaluation[state.cursor.current];
    }
    return null;
  }
});

export const getStats = selector({
  key: 'StudySessionStats',
  get: ({ get }) => {
    const state = get(studySessionState);

    if(state?.stats) {
      return {
        count: state.stats?.count,
        average: state.stats?.average.toFixed(2) ?? null,
      };
    }
    return null;
  }
});

export const getHowManyLeftAmount = selector({
  key: 'GetHowManyLeftAmount',
  get: ({ get }) => {
    const state = get(studySessionState);

    if(state) {
      return Object.values(state.evaluation).reduce((acc, value) => {
        if(value === null) {
          acc += 1;
        }
        return acc;
      }, 0);
    }
    return 0;
  }
});
