import NavigationBar from '../components/NavigationBar/NavigationBar';
import LessonsList from '../components/LessonsList/LessonsList';
import { Box, Button, Grid, Stack } from '@mui/material';
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';
import ApiService from '../services/ApiService';
import { LessonType } from '../enums';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../hooks/utils';
import { useEffectOnce } from 'usehooks-ts';
import { useLessons } from '../hooks/lessons';
import LessonsListFilters from '../components/LessonsList/LessonsListFilters';
import React from 'react';

const ListStack = styled(Stack)`
  max-width: 460px;
  margin: 24px auto 0;
  display: flex;
  align-items: center
`;

const FiltersBox = styled(Box)`
  padding: 0 16px;
`;

const CreateLessonButton = () => {
  const navigate = useNavigate();
  const translation = useTranslation();

  const handleCreateLesson = async () => {
    const lessonResponse = await ApiService.createLesson({
      header: translation('components.list.newLessonHeader'),
      isPublic: false,
      type: LessonType.FLASHCARDS
    });

    if(lessonResponse.statusCode === 200) {
      toast.success(translation( 'toasts.createLessonSuccess'));
      navigate(`/lesson/${lessonResponse.data.header}/${lessonResponse.data.shortId}?initialMode=TEXT_EDIT`);
    } else {
      toast.error(translation( 'toasts.createLessonError'));
    }
  };

  return (
    <Button variant="outlined" onClick={handleCreateLesson}>
      <FormattedMessage id="components.list.newLessonButton" />
    </Button>
  );
};

const HomePage = () => {
  const { refetch } = useLessons();

  useEffectOnce(() => {
    refetch();
  });

  return (
    <>
      <NavigationBar path="home" leftSection={<CreateLessonButton />}/>
      <Box sx={{ background: '#ddd', minHeight: '100vh' }}>
        <Grid padding={2}>
          <ListStack spacing={2}>
            <FiltersBox>
              <LessonsListFilters />
            </FiltersBox>
            <LessonsList />
          </ListStack>
        </Grid>
      </Box>
    </>
  );
};

export default HomePage;
