import { useForm, SubmitHandler } from 'react-hook-form';
import { Button, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import FormInputField from '../components/form/FormInputField';
import { yupResolver } from '@hookform/resolvers/yup';
import { registerFormSchema } from './validationSchemas';
import { useAuth } from '../hooks/auth';

interface FormInput {
  name: string;
  email: string;
  password: string;
  repeatedPassword: string;
}

const FormStack = styled(Stack)`
  max-width: 400px;
  margin: 40px auto 0;
  display: flex;
  align-items: center;
  text-align: center;
`;

const RegisterForm = () => {
  const { control, handleSubmit, formState: { errors } } = useForm<FormInput>({
    resolver: yupResolver(registerFormSchema),
  });
  const { signUp } = useAuth();

  const onSubmit: SubmitHandler<FormInput> = async data => {
    await signUp(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormStack spacing={3}>
        <FormInputField<FormInput>
          {...{ control, errors }}
          name='name'
          defaultValue=''
          type='text'
          labelPath='forms.register.name'
        />
        <FormInputField<FormInput>
          {...{ control, errors }}
          name='email'
          defaultValue=''
          type='email'
          labelPath='forms.register.email'
        />
        <FormInputField<FormInput>
          {...{ control, errors }}
          name='password'
          defaultValue=''
          type='password'
          labelPath='forms.register.password'
        />
        <FormInputField<FormInput>
          {...{ control, errors }}
          name='repeatedPassword'
          defaultValue=''
          type='password'
          labelPath='forms.register.repeatPassword'
        />
        <Typography><FormattedMessage id='forms.register.registerMessage' /></Typography>
        <Button variant='contained' sx={{ maxWidth: 200 }} type='submit'>
          <FormattedMessage id='forms.register.registerButton' />
        </Button>
      </FormStack>
    </form>
  );
};

export default RegisterForm;
