import { Box, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { FormattedMessage } from 'react-intl';

export const FontSettings = () => {
  const [currentFontSize, setCurrentFontSize] = React.useState(16);

  useEffect(() => {
    const fontSize = parseInt(window.localStorage.getItem('fontSize') || '16');
    setCurrentFontSize(fontSize);
  }, []);

  const setFontSize = (fontSize: number) => {
    ReactGA.event({
      category: 'editor',
      action: 'setFontSize',
    });

    window.localStorage.setItem('fontSize', (fontSize).toString());
  };

  const handleChange = (event: React.MouseEvent<HTMLElement>, newFontSize: number) => {
    if (newFontSize !== null) {
      setCurrentFontSize(newFontSize);
      setFontSize(newFontSize);
    }
  };
    
  return (
    <Box sx={{ ml: 1, textAlign: 'center' }}>
      <Typography variant='subtitle2'><FormattedMessage id='forms.profile.changeFont' /></Typography>
      <ToggleButtonGroup
        color="primary"
        value={currentFontSize}
        exclusive
        onChange={handleChange}
        aria-label="Font size"
      >
        <ToggleButton value={8}>XS</ToggleButton>
        <ToggleButton value={12}>S</ToggleButton>
        <ToggleButton value={16}>M</ToggleButton>
        <ToggleButton value={20}>L</ToggleButton>
        <ToggleButton value={24}>XL</ToggleButton>
        <ToggleButton value={32}>XXL</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};
