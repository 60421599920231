import { styled } from '@mui/material';
import { useEditor } from '../../hooks/editor';
import { EditMode } from '../../enums';

// type Props = {
//   editMode: boolean;
// };

type ToolbarContainerProps = {
  show: boolean;
}

const ToolbarContainer = styled('div')<ToolbarContainerProps>`
  display: ${props => props.show ? 'block' : 'none'};
  border: none;
  margin: 0 4px;
  
  .toolbar {
    border: none;

    @media (min-width: 480px) and (max-width: 560px) {
      width: 112px;
    }
    
    @media(max-width: 768px) {
      .ql-image {
        display: none;
      }
    }
    
    @media (max-width: 480px) {
      width: 186px;
    }
    
    @media (max-width: 360px) {
      width: 112px;
    }

    button {
      height: 24px;
    }
  }
`;

const Toolbar = () => {
  const { state: { content, editMode }, currentEditorId } = useEditor();

  const ids = content.data.flatMap((item) => [`h-${item.id}`, `p-${item.id}`]);

  return (
    <>
      {ids.map((id) => (
        <ToolbarContainer key={`toolbar-${id}`} show={(editMode === EditMode.TEXT_EDIT) && currentEditorId === id}>
          <div className="toolbar" id={`toolbar-${id}`}>
            <button className="ql-bold" />
            <button className="ql-italic" />
            <button className="ql-underline" style={{ marginTop: 1 }}/>
            <button className="ql-strike" />
            <button className="ql-link" />
            <button className="ql-image" />
            <button className="ql-video" />
          </div>
        </ToolbarContainer>
      ))
      }
    </>
  );
};

export default Toolbar;
