import { useEffectOnce } from 'usehooks-ts';
import { useSocket } from '../hooks/socket';
import { useNavigate, useParams } from 'react-router-dom';
import React from 'react';
import NavigationBar from '../components/NavigationBar/NavigationBar';
import { Box, Grid, Stack, styled, Typography } from '@mui/material';
import { StudySessionCursor, StudySessionState, StudySessionStats, StudySessionUser } from '../types';
import {
  getCurrentCursor,
  getCurrentParagraph,
  getCurrentParagraphEvaluation,
  getFriendName,
  getSessionMode,
  getShowCard,
  getUserRole,
  studySessionState,
} from '../recoil/study';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { useAuth } from '../hooks/auth';
import { StudyEvaluationGrade, StudyEvaluationMode, StudyEvaluationRole } from '../enums';
import { NavigationButtons } from '../components/StudyEvaluation/NavigationButtons';
import { EvaluateButton } from '../components/StudyEvaluation/EvaluateButton';
import EvaluateContentItem from '../components/StudyEvaluation/EvaluateContentItem';
import { useModal } from 'react-modal-hook';
import EvaluateStatsModal from '../components/StudyEvaluation/modals/EvaluateStatsModal';
import { HowManyLeftInfo } from '../components/StudyEvaluation/HowManyLeftInfo';
import { StudentGrade } from '../components/StudyEvaluation/StudentGrade';
import useWakeLock from '../hooks/wakeLock';
import EvaluateWaitModal from '../components/StudyEvaluation/modals/EvaluateWaitModal';
import EvaluationSubheader from '../components/StudyEvaluation/EvaluationSubheader';
import { useBeforeUnload } from '../hooks/beforeUnload';
import { studySessionEvaluationState } from '../recoil/study/atom';
import EvaluateBox from '../components/StudyEvaluation/EvaluateBox';

const ContentEditorBox = styled(Stack)<{ fullHeight?: boolean }>`
  overflow-y: auto;
  overflow-x: visible;
  margin-left: 4px;
  margin-top: 4px;
  width: 100%;
  
  @media (max-width: 768px) {
    height: calc(100dvh - 64px - 56px - 16px);
  }
`;

const CurrentCursorBox = styled(Box)`
  display: flex;
  justify-content: center;
  margin-top: 12px;
`;

const StudyPageStyled = styled(Box)`
    height: 100dvh;
    background-color:  #ddd;
    width: 100%;  
`;

const UserModeView = ({ sessionId }: { sessionId?: string }) => {
  const { user } = useAuth();
  const userRole = useRecoilValue(getUserRole(user?._id));
  const currentCursor = useRecoilValue(getCurrentCursor);
  const currentGrade = useRecoilValue(getCurrentParagraphEvaluation);
  const mode = useRecoilValue(getSessionMode);
  const showGrade = useRecoilValue(getShowCard);

  useWakeLock();

  if (mode === StudyEvaluationMode.SINGLE) {
    return (
      <div>
        { sessionId && (showGrade || currentGrade) &&
          <EvaluateBox sessionId={sessionId} grade={currentGrade}/>
        }
        {sessionId &&
          <NavigationButtons
            sessionId={sessionId}
            rightButton={
              (!currentGrade && !showGrade) && <EvaluateButton />
            }
          />
        }
      </div>
    );
  }

  if (userRole === StudyEvaluationRole.LEARNER) {
    return (
      <div>
        {currentGrade && <StudentGrade grade={currentGrade} />}
        <CurrentCursorBox>
          {currentCursor && <Typography>{currentCursor.index + 1} / {currentCursor.total}</Typography>}
        </CurrentCursorBox>
      </div>
    );
  }

  if (userRole === StudyEvaluationRole.EVALUATOR) {
    return (
      <div>
        { sessionId && (showGrade || currentGrade) &&
          <EvaluateBox sessionId={sessionId} grade={currentGrade}/>
        }
        {sessionId &&
          <NavigationButtons
            sessionId={sessionId}
            rightButton={
              (!currentGrade && !showGrade) && <EvaluateButton />
            }
          />
        }
      </div>
    );
  }

  return null;
};

const StudyPage = () => {
  const { socket } = useSocket();
  const params = useParams();
  const setStudySessionState = useSetRecoilState(studySessionState);
  const resetSelfEvaluationState = useResetRecoilState(studySessionEvaluationState);
  const { user } = useAuth();
  const friendName = useRecoilValue(getFriendName(user?._id));
  const currentParagraph = useRecoilValue(getCurrentParagraph);
  const currentGrade = useRecoilValue(getCurrentParagraphEvaluation);
  const mode = useRecoilValue(getSessionMode);
  const userRole = useRecoilValue(getUserRole(user?._id));

  const navigate = useNavigate();
  const [showFinishedModal, hideModal] = useModal(() =>
    <EvaluateStatsModal
      sessionId={params.sessionId}
      onClose={() => {
        hideModal();
        navigate('/');
      }}
    />, [params.sessionId]);
  const [showWaitModal, hideWaitModal] = useModal(() => <EvaluateWaitModal
    onClose={hideWaitModal} />);

  useBeforeUnload('Twoja sesja nauki nie została zakończona, czy na pewno chcesz opuścić stronę?', true);

  const setWidth = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  useEffectOnce(() => {
    window.addEventListener('resize', setWidth);
    setWidth();
  });

  useEffectOnce(() => {
    socket.emit('study:data', { sessionId: params.sessionId }, (err: unknown, data: StudySessionState) => {
      if (!err) {
        setStudySessionState(data);
        resetSelfEvaluationState();
      }
      if (!data?.evaluator && data?.learner?.id && (data?.learner?.id === user?._id)) {
        showWaitModal();
      }
    });

    socket.on('client-error', (err: { statusCode: number }) => {
      if (err.statusCode === 403) {
        socket.emit('study:join', { sessionId: params.sessionId }, (err: unknown, data: StudySessionState) => {
          if (!err) {
            setStudySessionState(data);
          }
        });
      }
    });

    socket.on('cursor', (data: StudySessionCursor, callback) => {
      setStudySessionState(prevState => {
        if (prevState) {
          return ({
            ...prevState,
            cursor: data,
          });
        }
        return prevState;
      });
      resetSelfEvaluationState();

      callback('ack');
    });

    socket.on('evaluated', (data: { grade: StudyEvaluationGrade }, callback) => {
      setStudySessionState(prevState => {
        if (prevState) {
          return ({
            ...prevState,
            evaluation: {
              ...prevState.evaluation,
              [prevState.cursor.current]: data.grade,
            },
          });
        }
        return prevState;
      });

      callback('ack');
    });

    socket.on('study:evaluator-joined', (data: { status: string, evaluator: StudySessionUser }) => {
      hideWaitModal();

      setStudySessionState(prevState => {
        if (prevState) {
          return ({
            ...prevState,
            evaluator: data.evaluator,
          });
        }
        return prevState;
      });
    });


    socket.on('finished', (data: { status: 'FINISHED', stats: StudySessionStats }, callback) => {
      showFinishedModal();
      callback('ack');
    });

    return () => {
      socket.off('client-error');
      socket.off('cursor');
      socket.off('evaluated');
      socket.off('study:evaluator-joined');
    };
  });

  return (
    <StudyPageStyled>
      <NavigationBar activeIndicator/>
      <EvaluationSubheader evaluatorName={friendName} />
      <Grid sx={{ background: '#ddd', pr: 1 }} container>
        <div id='toolbar' style={{ visibility: 'hidden', background: '#ddd', padding: 0 }}></div>
        <Grid item md />
        <Grid item xs={12} md={8}>
          <HowManyLeftInfo />
          <ContentEditorBox justifyContent="space-between" direction="column">
            {
              currentParagraph &&
              <EvaluateContentItem
                item={currentParagraph}
                mode={mode}
                role={(mode === StudyEvaluationMode.SINGLE ? StudyEvaluationRole.LEARNER : userRole) ?? StudyEvaluationRole.LEARNER}
                grade={currentGrade}
              />
            }
            <UserModeView sessionId={params.sessionId} />
          </ContentEditorBox>
        </Grid>
        <Grid item md />
      </Grid>
    </StudyPageStyled>
  );
};

export default StudyPage;
