import { LessonContentItem } from '../../types';
import { Box, Paper, styled, Typography } from '@mui/material';
import React from 'react';
import ViewerSingleEditor from '../SharedLesson/ViewerSingleEditor';
import { StudyEvaluationGrade, StudyEvaluationMode, StudyEvaluationRole } from '../../enums';
import { useRecoilValue } from 'recoil';
import { getShowCard } from '../../recoil/study';

type DraggableContentItemProps = {
  item: LessonContentItem;
  role: StudyEvaluationRole;
  mode: StudyEvaluationMode | null;
  grade: StudyEvaluationGrade | null;
};

const ContentItemBox = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-auto-rows: min-content;
  gap: 8px;
  padding: 4px;
  margin-bottom: 8px;

  @media (max-width: 768px) {
    height: auto;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer */
    padding-bottom: 4px;
    
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const InfoText = styled(Typography)`
    font-size: 0.9rem;
`;

const HiddenQuestionBox = styled(Box)`
    position: absolute;
    background-color: #bbb;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    font-size: 24px;
    font-weight: bold;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  
    ::after {
      content: '？';
      top: 50%;
    }
`;

const TEXTS = {
  [StudyEvaluationMode.SINGLE]: {
    [StudyEvaluationRole.LEARNER]: ['Pytanie', 'Odpowiedź'],
    [StudyEvaluationRole.EVALUATOR]: ['Pytanie', 'Odpowiedź'],
  },
  [StudyEvaluationMode.COOPERATIVE]: {
    [StudyEvaluationRole.LEARNER]: ['Pytanie o', ''],
    [StudyEvaluationRole.EVALUATOR]: ['Zapytaj o', 'Odpowiedź'],
  },
  [StudyEvaluationMode.TEACHING]: {
    [StudyEvaluationRole.LEARNER]: ['', ''],
    [StudyEvaluationRole.EVALUATOR]: ['', ''],
  }
};

const LessonViewerContentItem = ({ item, role, mode, grade }: DraggableContentItemProps) => {
  const showCard = useRecoilValue(getShowCard);
  const isSingleMode = mode === StudyEvaluationMode.SINGLE;
  const isCooperativeMode = mode === StudyEvaluationMode.COOPERATIVE;
  const isLearner = role === StudyEvaluationRole.LEARNER;

  const showHiddenBox = (isCooperativeMode && isLearner && !grade) || (isSingleMode && !showCard && !grade);

  const Editors = (
    <>
      <Paper elevation={1}>
        <ViewerSingleEditor
          readOnly={true}
          value={item.header.delta}
          onChange={() => {}}
          id={`h-${item.id}`}
          handleFocus={() => {}}
        />
      </Paper>
      <Paper elevation={1} sx={{ position: 'relative' }}>
        { showHiddenBox && <HiddenQuestionBox />}
        <ViewerSingleEditor
          readOnly={true}
          value={item.paragraph.delta}
          onChange={() => {}}
          id={`p-${item.id}`}
          handleFocus={() => {}}
        />
      </Paper>
    </>
  );

  const Texts = mode &&(
    <>
      <InfoText variant="subtitle2" sx={{ fontSize: '0.9rem' }}>{TEXTS[mode][role][0]}</InfoText>
      <InfoText variant="subtitle2">{TEXTS[mode][role][1]}</InfoText>
    </>
  );

  return (
    <ContentItemBox
      key={item.id}
    >
      { Texts }
      { Editors }
    </ContentItemBox>
  );
};


export default LessonViewerContentItem;
