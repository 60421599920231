import { Box, Paper, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useRecoilValue } from 'recoil';
import { getSessionMode, getUserRole } from '../../recoil/study';
import { StudyEvaluationMode, StudyEvaluationRole } from '../../enums';
import { useAuth } from '../../hooks/auth';

const SubheaderBox = styled(Box)`
  height: 48px;
  display: flex;
  padding: 0 8px;
  background-color: #eee;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

type Props = {
	evaluatorName?: string | null;
};

const EvaluationSubheader = ({ evaluatorName }: Props) => {
  const { user } = useAuth();
  const role = useRecoilValue(getUserRole(user?._id));
  const mode = useRecoilValue(getSessionMode);

  const headerText = role === StudyEvaluationRole.EVALUATOR ? 'Pomagasz: ' : 'Pomaga Ci: ';

  return (
    <Paper elevation={1}>
      <SubheaderBox>
        <Typography variant='h6' sx={{ fontWeight: 600, marginTop: '4px' }}>
          { evaluatorName && `${headerText}${evaluatorName}` }
          { !evaluatorName && mode === StudyEvaluationMode.COOPERATIVE && 'Pomaga Ci inny użytkownik' }
          { mode === StudyEvaluationMode.SINGLE && 'Uczysz się sam' }
        </Typography>
      </SubheaderBox>
    </Paper>
  );
};

export default EvaluationSubheader;
