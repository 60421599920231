import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { getHowManyLeftAmount } from '../../recoil/study';

export const HowManyLeftInfo = () => {
  const amount = useRecoilValue(getHowManyLeftAmount);

  return (
    <Typography variant="subtitle1" sx={{ textAlign: 'right' }}>
      <FormattedMessage id="components.lesson.study.page.howManyLeft"/>
      <b> {amount}</b>
    </Typography>
  );
};
