import { useEffectOnce } from 'usehooks-ts';
import { useSocket } from '../hooks/socket';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import NavigationBar from '../components/NavigationBar/NavigationBar';
import { Box, Button, Grid, Paper, Stack, styled, Typography } from '@mui/material';
import PresentationSubheader from '../components/PresentationView/PresentationSubheader';
import ViewerSingleEditor from '../components/SharedLesson/ViewerSingleEditor';
import { useTranslation } from '../hooks/utils';

const ContentEditorBox = styled(Stack)<{ fullHeight?: boolean }>`
  overflow-y: auto;
  overflow-x: visible;
  margin: 16px auto;
  width: 360px;
  
  @media (max-width: 768px) {
    height: calc(100dvh - 64px - 56px - 16px);
  }
`;

const EmptyStateBox = styled(Stack)`
  overflow-y: auto;
  overflow-x: visible;
  margin: 32px auto;
  text-align: center;
`;

const StudyPageStyled = styled(Box)`
    height: 100dvh;
    background-color:  #ddd;
    width: 100%;  
`;

type PresentationState = {
  id: string,
  content: string
  header: string
  focusedParagraphId: string
}

const StudyPage = () => {
  const { socket } = useSocket();
  const params = useParams();
  const translation = useTranslation();
  const [presentationState, setPresentationState] = useState<PresentationState | null>();
  const [currentFontSize, setCurrentFontSize] = React.useState(16);
  const [finished, setFinished] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fontSize = parseInt(window.localStorage.getItem('fontSize') || '16');
    setCurrentFontSize(fontSize);
  }, []);

  useEffectOnce(() => {
    if(!presentationState) {
      socket.emit('presentation:join', { sessionId: params.sessionId }, (err: unknown, data: PresentationState) => {
        if (!err) {
          setPresentationState(data);
        }
      });
    }

    socket.on('presentation:focus-change', (data: Pick<PresentationState, 'focusedParagraphId' | 'content'>) => {
      setPresentationState((prevState) => {
        if (prevState) {
          return {
            ...prevState,
            focusedParagraphId: data.focusedParagraphId,
            content: data.content
          };
        }
        return null;
      });
    });

    socket.on('presentation:finished', () => {
      setFinished(true);
    });

    return () => {
      socket.off('presentation:focus-change');
    };
  });

  if(finished) {
    return (
      <StudyPageStyled>
        <NavigationBar activeIndicator/>
        <PresentationSubheader header={presentationState?.header} />
        <Grid sx={{ background: '#ddd', pr: 1, fontSize: `${currentFontSize}px` }} container>
          <div id='toolbar' style={{ visibility: 'hidden', background: '#ddd', padding: 0 }}></div>
          <Grid item md />
          <Grid item xs={12} md={8}>
            <EmptyStateBox>
              <Typography variant="h5">{translation('components.presentation.finished')}</Typography>
            </EmptyStateBox>
            <Button variant='contained' onClick={() => navigate('/')}>
              {translation('components.presentation.finishedButton')}
            </Button>
          </Grid>
          <Grid item md />
        </Grid>
      </StudyPageStyled>
    );
  }

  return (
    <StudyPageStyled>
      <NavigationBar activeIndicator/>
      <PresentationSubheader header={presentationState?.header} />
      <Grid sx={{ background: '#ddd', pr: 1, fontSize: `${currentFontSize}px` }} container>
        <div id='toolbar' style={{ visibility: 'hidden', background: '#ddd', padding: 0 }}></div>
        <Grid item md />
        <Grid item xs={12} md={8}>
          { !presentationState?.focusedParagraphId && (
            <EmptyStateBox>
              <Typography variant="h5">{translation('components.presentation.empty')}</Typography>
            </EmptyStateBox>
          )}
          {
            presentationState?.focusedParagraphId && (
              <ContentEditorBox>
                <Paper elevation={1}>
                  <ViewerSingleEditor
                    readOnly
                    value={presentationState?.content}
                    onChange={() => {}}
                    fontSize={currentFontSize}
                    id={presentationState?.focusedParagraphId ?? ''}
                    handleFocus={() => {}}
                  />
                </Paper>
              </ContentEditorBox>
            )
          }
        </Grid>
        <Grid item md />
      </Grid>
    </StudyPageStyled>
  );
};

export default StudyPage;
