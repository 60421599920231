import React from 'react';
import NavigationBar from '../NavigationBar/NavigationBar';
import { Grid, styled } from '@mui/material';
import LessonsListSidebar from './LessonsListSidebar';
import LessonHeaderInput from './LessonHeaderInput';
import LessonContentEditors from './LessonContentEditors';
import ReactQuill from 'react-quill';
import { useEditor } from '../../hooks/editor';
import { EditMode } from '../../enums';
import { LessonEditorToolbar } from './LessonEditorToolbar';
import { Helmet } from 'react-helmet';
import useSWR from 'swr';
import ApiService from '../../services/ApiService';
import { LessonStudySession } from '../../types';
import { useBeforeUnload } from '../../hooks/beforeUnload';

const fetcher = (url: string) => ApiService.get<LessonStudySession>(url);

const LessonEditorPanel = () => {
  const {
    state,
    currentEditor,
    setCurrentEditorId,
    setPresentationFocus,
    isLatestParagraph,
    addParagraph,
  } = useEditor();

  const apiUrl = `/lessons/${state.lesson?._id}/study/${state.studySessionId}`;

  const { data: lessonStudySessionResponse } = useSWR(state.studySessionId ? apiUrl : null, fetcher);

  const lessonStudySession = lessonStudySessionResponse?.statusCode === 200 ? lessonStudySessionResponse.data : undefined;

  const handleFocus = (editor: ReactQuill | null, id: string | null) => {
    if(state.editMode === EditMode.CONTENT_PRESENTATION && id) {
      setPresentationFocus(id);
    }

    if(state.editMode === EditMode.TEXT_EDIT) {
      currentEditor.current = editor;
      setCurrentEditorId(id);
      if (id) {
        if (isLatestParagraph(id)) {
          addParagraph({});
        }
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>{state.lesson?.header ? `MemoFriend - ${state.lesson?.header}` : 'MemoFriend' }</title>
      </Helmet>
      <LessonEditorToolbar />
      <LessonHeaderInput />
      <LessonContentEditors handleFocus={handleFocus} studySession={lessonStudySession} />
    </>
  );
};

const LeftColumn = styled(Grid)`
  @media print {
    display: none;
  }
`;

const RightColumn = styled(Grid)`
  @media print {
    display: block;
    width: 100%;
  }
`;

const LessonEditor = () => {
  const { state: { editMode } } = useEditor();

  useBeforeUnload('Czy na pewno chcesz opuścić stronę?', editMode === 'CONTENT_PRESENTATION');

  return (
    <div>
      <NavigationBar activeIndicator={editMode !== EditMode.READ_ONLY} />
      <Grid container height='calc(100vh - 48px)' sx={{ background: '#ddd' }}>
        <LeftColumn item md={4} display={{ xs: 'none', md: 'block' }}>
          <LessonsListSidebar />
        </LeftColumn>
        <RightColumn item xs={12} md={8} sx={{ borderLeft: '1px #e9e9e9 solid' }}>
          <LessonEditorPanel />
        </RightColumn>
      </Grid>
    </div>
  );
};

export default LessonEditor;
