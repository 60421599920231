import NavigationBar from '../components/NavigationBar/NavigationBar';
import { Box, Grid, styled } from '@mui/material';

const PrivacyPolicyBox = styled(Box)`
  max-width: 640px;
  text-align: left;
  margin: 0 auto;
`;

const PrivacyPolicyPage = () => {
  return (
    <>
      <NavigationBar path='privacy' />
      <Box>
        <Grid padding={2}>
          <PrivacyPolicyBox>
            <ol>
              <li>
                <p>
                                  Serwis nie zbiera w sposób automatyczny żadnych informacji, z wyjątkiem informacji
                                  zawartych w plikach cookies.
                </p>
              </li>
              <li>
                <p>
                                  Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w szczególności pliki
                                  tekstowe, które przechowywane są w urządzeniu końcowym
                                  Użytkownika Serwisu i przeznaczone są do korzystania ze stron internetowych Serwisu.
                                  Cookies zazwyczaj zawierają nazwę strony internetowej,
                                  z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.
                </p>
              </li>
              <li>
                <p>
                                  Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika Serwisu pliki cookies
                                  oraz uzyskującym do nich dostęp jest operator Serwisu
                                  Memofriend.com z siedzibą pod adresem ul. Powstańców 60 D, 31­670 Kraków, Polska
                </p>
              </li>
              <li>
                <p>Pliki cookies wykorzystywane są w celu:</p>
                <ol>
                  <li>
                                      dostosowania zawartości stron internetowych Serwisu do preferencji Użytkownika
                                      oraz optymalizacji korzystania ze stron internetowych; w
                                      szczególności pliki te pozwalają rozpoznać urządzenie Użytkownika Serwisu i
                                      odpowiednio wyświetlić stronę internetową, dostosowaną do jego
                                      indywidualnych potrzeb;
                  </li>
                  <li>
                                      tworzenia statystyk, które pomagają zrozumieć, w jaki sposób Użytkownicy Serwisu
                                      korzystają ze stron internetowych, co umożliwia
                                      ulepszanie ich struktury i zawartości;
                  </li>
                  <li>
                                      utrzymanie sesji Użytkownika Serwisu (po zalogowaniu), dzięki której Użytkownik
                                      nie musi na każdej podstronie Serwisu ponownie wpisywać
                                      loginu i hasła;
                  </li>
                </ol>
              </li>
              <li>
                <p>
                                  W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików cookies: „sesyjne” (<em>session
                                  cookies</em>) oraz „stałe” (<em
                  >persistent cookies</em
                  >). Cookies „sesyjne” są plikami tymczasowymi, które przechowywane są w urządzeniu
                                  końcowym Użytkownika do czasu wylogowania, opuszczenia
                                  strony internetowej lub wyłączenia oprogramowania (przeglądarki internetowej). „Stałe”
                                  pliki cookies przechowywane są w urządzeniu końcowym
                                  Użytkownika przez czas określony w parametrach plików cookies lub do czasu ich
                                  usunięcia przez Użytkownika.
                </p>
              </li>
              <li>
                <p>W ramach Serwisu stosowane są następujące rodzaje plików cookies:</p>
                <ul>
                  <li>Autentykacja i sesja użytkownika</li>
                  <li>Potwierdzenie zgody na politykę prywatnosci</li>
                </ul>
              </li>
              <li>
                <p>
                                  W wielu przypadkach oprogramowanie służące do przeglądania stron internetowych
                                  (przeglądarka internetowa) domyślnie dopuszcza przechowywanie
                                  plików cookies w urządzeniu końcowym Użytkownika. Użytkownicy Serwisu mogą dokonać w
                                  każdym czasie zmiany ustawień dotyczących plików
                                  cookies. Ustawienia te mogą zostać zmienione w szczególności w taki sposób, aby
                                  blokować automatyczną obsługę plików cookies w ustawieniach
                                  przeglądarki internetowej bądź informować o ich każdorazowym zamieszczeniu w
                                  urządzeniu Użytkownika Serwisu. Szczegółowe informacje o
                                  możliwości i sposobach obsługi plików cookies dostępne są w ustawieniach
                                  oprogramowania (przeglądarki internetowej).
                </p>
              </li>
              <li>
                <p>
                                  Operator Serwisu informuje, że ograniczenia stosowania plików cookies mogą wpłynąć na
                                  niektóre funkcjonalności dostępne na stronach
                                  internetowych Serwisu.
                </p>
              </li>
              <li>
                <p>
                                  Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika Serwisu i wykorzystywane
                                  mogą być również przez współpracujących z operatorem
                                  Serwisu reklamodawców oraz partnerów.
                </p>
              </li>
              <li>
                <p>
                                  Więcej informacji na temat plików cookies dostępnych jest w sekcji „Pomoc” w menu
                                  przeglądarki internetowej.
                </p>
              </li>
            </ol>
            <br /><br />
            <p>
                          Poniższy wzór polityki cookies chroniony jest prawem autorskim, które przysługują IAB Polska.
            </p>
            <center>
              <p>
                              Źródło:
                <a href='http://wszystkoociasteczkach.pl/'
                  title='Wszystko o ciasteczkach'>wszystkoociasteczkach.pl</a>
              </p>
            </center>
          </PrivacyPolicyBox>
        </Grid>
      </Box>
    </>
  );
};

export default PrivacyPolicyPage;
