import {
  AppBar,
  Box, Grid,
  Paper,
  Toolbar,
  Typography,
} from '@mui/material';
import logo from '../../logo.svg';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import NavigationMenu from './NavigationMenu';
import { ReactNode } from 'react';

type Props = {
  path?: string;
  leftSection?: ReactNode;
  rightSection?: ReactNode;
  activeIndicator?: boolean;
}

const SubheaderBox = styled(Box)`
  height: 48px;
  display: flex;
  padding: 0 8px;
  background-color: #eee;
  justify-content: center;
  align-items: center;
`;

const NavigationBar = ({ activeIndicator, path, leftSection, rightSection }: Props) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position='static'>
        <Toolbar variant='dense'>
          <Link to='/' style={{ height: '28px', flexGrow: 1 }}>
            <img src={logo} width='28px' alt='logo' />
          </Link>
          <NavigationMenu activeIndicator={activeIndicator ?? false}/>
        </Toolbar>
      </AppBar>
      {path &&
        <Paper elevation={1}>
          <SubheaderBox>
            <Grid container>
              <Grid item xs={0} sm={4}>
                {leftSection}
              </Grid>
              <Grid xs={6} sm={4} item sx={{ textAlign: 'center' }}>
                <Typography variant='h6' sx={{ fontWeight: 600, marginTop: '4px' }}>
                  <FormattedMessage id={`pages.${path}`} />
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4} sx={{ display: 'flex', justifyContent: 'end' }}>
                {rightSection}
              </Grid>
            </Grid>
          </SubheaderBox>
        </Paper>
      }
    </Box>
  );
};

export default NavigationBar;
