import NavigationBar from '../components/NavigationBar/NavigationBar';
import { Box, Grid } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ResetPasswordForm from '../forms/ResetPasswordForm';
import { useEffect } from 'react';

const LoginPage = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();

  useEffect(() => {
    if(!token) {
      navigate('/login');
    }
  }, [navigate, token]);

  if(!token) {
    return null;
  }

  return (
    <>
      <NavigationBar path='resetPassword' />
      <Box>
        <Grid padding={2}>
          <ResetPasswordForm token={token} />
        </Grid>
      </Box>
    </>
  );
};

export default LoginPage;
