import { useRecoilValue, useSetRecoilState } from 'recoil';
import { getCurrentParagraphEvaluation } from '../../recoil/study';
import { Box, Button } from '@mui/material';
import ReactGA from 'react-ga4';
import { studySessionEvaluationState } from '../../recoil/study/atom';

export const EvaluateButton = () => {
  const setStudySessionEvaluation = useSetRecoilState(studySessionEvaluationState);
  const grade = useRecoilValue(getCurrentParagraphEvaluation);

  const handleEvaluate = () => {
    setStudySessionEvaluation((prevState) => ({ ...prevState, showEvaluationBox: true }));

    ReactGA.event({
      category: 'study',
      action: 'openEvaluateMode',
    });
  };

  if(grade) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
      <Button variant="contained" onClick={handleEvaluate}>
        Pokaż i oceń
      </Button>
    </Box>
  );
};