import NavigationBar from '../components/NavigationBar/NavigationBar';
import { Box, Typography, Stack, styled } from '@mui/material';
import { useEffect } from 'react';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';
import { useNavigate } from 'react-router-dom';

import maintenanceImage from '../resources/maintenance.jpeg';

const Container = styled(Box)`
  height: 100%;
  max-width: 480px;
  margin: 0 auto;
`;

const Image = styled('img')`
    width: 100%;
    height: 300px;
    object-fit: scale-down;
`;

const MaintenancePage = () => {
  const maintenanceMode = useFlag('maintenance-mode');
  const flagsReady = useFlagsStatus();
  const navigate = useNavigate();

  useEffect(() => {
    if(flagsReady && !maintenanceMode) {
      return navigate('/');
    }
  }, [flagsReady, maintenanceMode, navigate]);

  return (
    <>
      <NavigationBar path='maintenance' />
      <Container>
        <Stack padding={2} spacing={2}>
          <Image src={maintenanceImage} alt="Maintenance" />
          <Typography variant='h6' align="center">
                Przepraszamy, MemoFriend jest aktualnie w trakcie przerwy technicznej.
          </Typography>
          <Typography variant='body1' align="center">
            Pracujemy nad ulepszeniami, które pozwolą Ci jeszcze wygodniej i efektywniej korzystać z aplikacji.
            Twoje notatki są bezpieczne - po prostu na chwilę zawiesiliśmy możliwość przeglądania ich, tworzenia i edycji istniejących.
          </Typography>
          <Typography variant='body1' align="center">
            Postaramy się zakończyć prace jak najszybciej się da, abyś mógł ponownie w pełni korzystać z MemoFriend.
            Bardzo przepraszamy za niedogodności. Doceniamy Twoją cierpliwość i zrozumienie.
          </Typography>
          <Typography variant='body2' align="center">
                Dziękujemy za korzystanie z MemoFriend!
          </Typography>
        </Stack>
      </Container>
    </>
  );
};

export default MaintenancePage;
