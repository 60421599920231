import LessonListItem from './LessonListItem';
import React from 'react';
import {
  Button, CircularProgress,
  List, Stack,
} from '@mui/material';
import styled from '@emotion/styled';
import { useLessons } from '../../hooks/lessons';

const ListLoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const StyledStack = styled(Stack)`
    padding: 0 8px;
    flex-grow: 1;
`;

const StyledList = styled(List)`
  width: 100%;
  min-width: 280px;
  
  @media (min-width: 480px) and (max-width: 800px) {
    min-width: 420px;
  }
`;

const LessonsList = () => {
  const { lessons, fetchMore, isLoading, hasMore } = useLessons();

  return (
    <StyledStack spacing={2} className="list">
      <StyledList>
        {lessons?.map((lesson) => (<LessonListItem key={lesson._id} lesson={lesson} />))}
      </StyledList>
      { !isLoading && hasMore &&
          <Button sx={{ alignSelf: 'center' }} onClick={() => fetchMore()}>Pokaż więcej</Button>
      }
      { isLoading &&
          <ListLoadingContainer>
            <CircularProgress color="primary" />
          </ListLoadingContainer>
      }
    </StyledStack>
  );
};

export default LessonsList;
