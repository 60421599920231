import React from 'react';
import NavigationBar from '../NavigationBar/NavigationBar';
import { Grid } from '@mui/material';
import { LessonViewerToolbar } from './LessonViewerToolbar';
import { Lesson } from '../../types';
import LessonViewerHeaderInput from './LessonViewerHeaderInput';
import LessonViewerContent from './LessonViewerContent';

type Props = {
  lesson: Lesson;
  token: string;
  isLoading: boolean;
}

const SharedLessonViewer = ({ lesson, token, isLoading }: Props) => {
  return (
    <div>
      <NavigationBar activeIndicator={false} />
      <Grid sx={{ background: '#ddd' }}>
        <Grid item xs={12} md={12}>
          <LessonViewerToolbar token={token} />
          <LessonViewerHeaderInput lesson={lesson} isLoading={isLoading} />
          <Grid container>
            <Grid item md />
            <Grid item xs={12} md={8}>
              <LessonViewerContent lesson={lesson} />
            </Grid>
            <Grid item md />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default SharedLessonViewer;
