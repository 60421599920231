import React from 'react';
import { Button, Stack } from '@mui/material';
import styled from '@emotion/styled';
import { useSocket } from '../../hooks/socket';
import { StudyEvaluationGrade } from '../../enums';
import ReactGA from 'react-ga4';

type Props = {
    sessionId?: string;
    grade: StudyEvaluationGrade | null;
}

const ModalStack = styled(Stack)`
  max-width: 480px;
  min-width: 300px;
  margin: 12px auto;
  display: flex;
  text-align: center;
  align-items: center;
`;

const EvaluateBox = ({ sessionId, grade }: Props) => {
  const { socket } = useSocket();

  const handleEvaluate = (evaluationGrade: StudyEvaluationGrade) => {
    ReactGA.event({
      category: 'study',
      action: `evaluate:${evaluationGrade}`,
    });

    socket.emit('study:evaluate', {
      sessionId,
      grade: evaluationGrade,
    }, (callback: (message: string) => void) => callback('received'));
  };

  return (
    <ModalStack spacing={2}>
      <Button fullWidth size="large" variant={grade === StudyEvaluationGrade.GREAT ? 'contained' : 'outlined'} onClick={() => handleEvaluate(StudyEvaluationGrade.GREAT)}>
            ❤️ Super! Umiesz to!
      </Button>
      <Button fullWidth size="large" variant={grade === StudyEvaluationGrade.NOT_BAD ? 'contained' : 'outlined'} onClick={() => handleEvaluate(StudyEvaluationGrade.NOT_BAD)}>
            👍 Nieźle, ale jeszcze powtórzmy
      </Button>
      <Button fullWidth size="large" variant={grade === StudyEvaluationGrade.REPEAT ? 'contained' : 'outlined'} onClick={() => handleEvaluate(StudyEvaluationGrade.REPEAT)}>
            😏 Wróćmy do tego później
      </Button>
    </ModalStack>
  );
};

export default EvaluateBox;
