import { Response } from '../types';
import {
  SignInParams,
  LoginResponseData,
  SignUpParams,
  UserResponseData,
  ResetPasswordParams,
} from './types';

class AuthService {
  private readonly apiUrl: string;

  constructor() {
    this.apiUrl = process.env.REACT_APP_API_URL as string;
  }

  login(params: SignInParams) {
    return this.request('/login', {
      method: 'POST',
      body: JSON.stringify(params),
    }) as Response<LoginResponseData>;
  }

  logout() {
    return this.request('/logout', {
      method: 'POST',
    }) as Response<null>;
  }

  register(params: SignUpParams) {
    return this.request('/user', {
      method: 'POST',
      body: JSON.stringify(params),
    }) as Response<null>;
  }

  getUser() {
    return this.request('/user', {
      method: 'GET',
    }) as Response<UserResponseData>;
  }

  resetPassword(params: ResetPasswordParams) {
    return this.request('/user/change-password', {
      method: 'POST',
      body: JSON.stringify(params),
    }) as Response<UserResponseData>;
  }

  private async request(path: string, options: RequestInit) {
    const result = await fetch(`${this.apiUrl}${path}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      ...options,
    });

    return result.json();
  }
}

export default AuthService;
