import { useEffect, useState } from 'react';

const useWakeLock = (): WakeLockSentinel | null => {
  const [wakeLock, setWakeLock] = useState<WakeLockSentinel | null>(null);

  useEffect(() => {
    const requestWakeLock = async () => {
      if ('wakeLock' in navigator) {
        try {
          const lock = await navigator.wakeLock.request('screen');
          setWakeLock(lock);
        } catch (error) {
          console.error('Failed to request wake lock:', error);
        }
      } else {
        console.warn('Wake Lock API not supported in this browser');
      }
    };

    requestWakeLock();

    return () => {
      wakeLock?.release().then(() => setWakeLock(null));
    };
  }, [wakeLock]);

  return wakeLock;
};

export default useWakeLock;
