import { Box, Paper, Skeleton, styled } from '@mui/material';
import React from 'react';

const ContentItemBox = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-auto-rows: min-content;
  gap: 8px;
  padding: 4px;
`;

const ContentItemSkeleton = () => {
  return (
    <ContentItemBox>
      <Paper elevation={1} sx={{ padding: '4px 8px' }}>
        <Skeleton animation='wave' height={38}/>
      </Paper>
      <Paper elevation={1} sx={{ padding: '4px 8px' }}>
        <Skeleton animation='wave' height={38}/>
      </Paper>
    </ContentItemBox>
  );
};

export default ContentItemSkeleton;
