import { useEffect } from 'react';

export const useBeforeUnload = (message: string, when: boolean) => {
  useEffect(() => {
    if (!when) {
      return;
    }
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = message;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [message, when]);
};
