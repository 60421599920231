import { LessonContentItem, LessonContentItemType } from '../../types';
import { DeltaStatic } from 'quill';
import ReactQuill from 'react-quill';
import { Box, Chip, Divider, IconButton, Paper, styled } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SingleEditor from './SingleEditor';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useEditor } from '../../hooks/editor';
import { EditMode, StudyEvaluationGrade } from '../../enums';

type DraggableContentItemProps = {
  item: LessonContentItem;
  index: number;
  editorLoading: boolean;
  handleChange: (id: string, type: LessonContentItemType) => (value: string, delta: DeltaStatic) => void,
  handleFocus: (editor: ReactQuill | null, id: string | null) => void;
  handleDelete: (id: string) => void;
  onAddNewParagraph: (id: string) => void;
  evaluation?: StudyEvaluationGrade | null;
};

type ContentItemBoxProps = {
  editMode: EditMode;
};

const getGridTemplateColumns = (editMode: EditMode) => {
  if (editMode === EditMode.CONTENT_EDIT) {
    return 'repeat(2, minmax(0, 1fr)) 18px';
  }
  return 'repeat(2, minmax(0, 1fr))';
};

const ContentItemBox = styled(Box, { shouldForwardProp: (prop) => prop !== 'editMode' })<ContentItemBoxProps>`
  display: grid;
  grid-template-columns: ${({ editMode }) => getGridTemplateColumns(editMode)};
  grid-auto-rows: min-content;
  gap: 8px;
  padding: 4px;
  cursor: ${({ editMode }) => editMode === EditMode.CONTENT_EDIT ? 'grab' : ''};

  .ql-editor > * {
    cursor: ${({ editMode }) => editMode === EditMode.CONTENT_EDIT ? 'grab' : ''};
  }
`;

const DraggableContentItem = ({ 
  item, index, editorLoading, handleChange, handleFocus, handleDelete, onAddNewParagraph, evaluation
}: DraggableContentItemProps) => {

  const { state: { editMode, studySessionFilters } } = useEditor();
  const isInContentEdit = editMode === EditMode.CONTENT_EDIT;
  const isNotInTextEdit = editMode !== EditMode.TEXT_EDIT;
  const isInStudyHistoryMode = editMode === EditMode.STUDY_HISTORY;

  const isDimmed = isInStudyHistoryMode && ((!evaluation && studySessionFilters && studySessionFilters.length > 0) || (evaluation &&
    studySessionFilters &&
    studySessionFilters.length > 0 &&
    !studySessionFilters?.includes(evaluation)));

  const Editors = (
    <>
      <Paper elevation={1}>
        <SingleEditor
          readOnly={isNotInTextEdit}
          value={item.header.delta}
          marker={evaluation}
          dimmedMode={!!isDimmed}
          onChange={handleChange(item.id, 'header')}
          id={`h-${item.id}`}
          handleFocus={handleFocus}
        />
      </Paper>
      <Paper elevation={1}>
        <SingleEditor
          readOnly={isNotInTextEdit}
          value={item.paragraph.delta}
          dimmedMode={!!isDimmed}
          onChange={handleChange(item.id, 'paragraph')}
          id={`p-${item.id}`}
          handleFocus={handleFocus}
        />
      </Paper>
    </>
  );

  return (
    <Draggable
      key={item.id}
      draggableId={item.id}
      index={index}
      isDragDisabled={editMode !== EditMode.CONTENT_EDIT}
    >
      {(provided) => (
        <div ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <ContentItemBox
            key={item.id}
            editMode={editMode}
            style={{ visibility: editorLoading ? 'hidden' : 'visible' }}
          >
            { Editors }
            {isInContentEdit &&
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <IconButton color='error' aria-label='remove paragraph' size='small'
                  onClick={() => handleDelete(item.id)}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </div>
            }
          </ContentItemBox>
          {isInContentEdit &&
            <Divider sx={{ width: 'calc(100% - 28px)' }}>
              <Chip label='+' size='small' onClick={() => onAddNewParagraph(item.id)} />
            </Divider>
          }
        </div>
      )}
    </Draggable>
  );
};


export default DraggableContentItem;
