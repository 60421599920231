import { MouseEvent, useState } from 'react';
import { useAuth } from '../../hooks/auth';
import { useNavigate } from 'react-router-dom';
import { Avatar, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import SocketIndicatorBadge from './SocketIndicatorBadge';
import { getSocketIndicatorTooltip } from './utils';
import { useSocket } from '../../hooks/socket';

type Props = {
  activeIndicator: boolean;
}
const NavigationMenu = ({ activeIndicator }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  const { socketStatus } = useSocket();

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLoginMenuButtonClick = () => {
    handleClose();
    navigate('/login');
  };

  const handleRegisterMenuButtonClick = () => {
    navigate('/register');
    handleClose();
  };

  const handleLogoutMenuButtonClick = async () => {
    await signOut();
    window.location.reload();
    handleClose();
  };

  const handleProfileMenuButtonClick = () => {
    navigate('/profile');
    handleClose();
  };

  if (user) {
    return (
      <div>
        <IconButton color="default" href="https://www.memofriend.com/">
          <HomeIcon />
        </IconButton>
        <Tooltip placement='bottom-end' title={getSocketIndicatorTooltip(socketStatus, activeIndicator)}>
          <SocketIndicatorBadge
            active={activeIndicator}
            status={socketStatus}
            overlap='circular'
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant='dot'
          >
            <Avatar
              sx={{ width: 32, height: 32, cursor: 'pointer' }}
              src={user.image ?? undefined}
              onClick={handleMenu}
              alt={user.name}
              aria-controls='menu-appbar'
              aria-haspopup='true'
              aria-label='menu'
            />
          </SocketIndicatorBadge>
        </Tooltip>
        <Menu
          id='menu-appbar'
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleProfileMenuButtonClick}><FormattedMessage id='menu.profile' /></MenuItem>
          <MenuItem onClick={handleLogoutMenuButtonClick}><FormattedMessage id='menu.logout' /></MenuItem>
        </Menu>
      </div>
    );
  }

  return (
    <div>
      <IconButton
        size='large'
        edge='end'
        onClick={handleMenu}
        aria-controls='menu-appbar'
        aria-haspopup='true'
        color='inherit'
        aria-label='menu'
        sx={{ mr: 1 }}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id='menu-appbar'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleLoginMenuButtonClick}><FormattedMessage id='menu.login' /></MenuItem>
        <MenuItem onClick={handleRegisterMenuButtonClick}><FormattedMessage id='menu.register' /></MenuItem>
      </Menu>
    </div>
  );
};

export default NavigationMenu;
