import { useForm, SubmitHandler } from 'react-hook-form';
import { Avatar, Button, Grid, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { profileFormSchema } from './validationSchemas';
import FormInputField from '../components/form/FormInputField';
import { useAuth } from '../hooks/auth';
import { ChangeEventHandler } from 'react';
import ApiService from '../services/ApiService';
import { toast } from 'react-hot-toast';
import { useTranslation } from '../hooks/utils';
import { useSWRConfig } from 'swr';
import { omitBy } from 'lodash';
import { FontSettings } from '../components/FontSettings';

interface FormInput {
  email: string;
  name: string;
  password?: string;
  repeatedPassword?: string;
}

const FormStack = styled(Stack)`
  max-width: 400px;
  margin: 40px auto 0;
  display: flex;
  align-items: center
`;

const ProfileForm = () => {
  const { user } = useAuth();
  const translation = useTranslation();
  const { control, handleSubmit, formState: { errors } } = useForm<FormInput>({
    defaultValues: {
      name: user?.name,
      email: user?.email,
    },
    resolver: yupResolver(profileFormSchema),
  });
  const { mutate } = useSWRConfig();

  const onSubmit: SubmitHandler<FormInput> = async data => {
    const input = omitBy(data, item => item == null);

    await ApiService.updateUserProfile({
      ...input,
      email: data.email,
      name: data.name,
    });

    toast.success(translation('toasts.profileUpdatedSuccess'));
  };

  const handleAvatarChange: ChangeEventHandler<HTMLInputElement> = async (event) => {
    if(event.target.files) {
      await ApiService.updateUserAvatar(event.target.files[0]);

      await mutate('/user');
      toast.success(translation('toasts.profileUpdatedSuccess'));
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormStack spacing={3}>
        <FormInputField<FormInput>
          {...{ control, errors }}
          name='name'
          defaultValue=''
          type='text'
          labelPath='forms.profile.name'
        />
        <FormInputField<FormInput>
          {...{ control, errors }}
          name='email'
          defaultValue=''
          type='email'
          labelPath='forms.profile.email'
        />
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <Avatar src={user?.image ?? undefined} sx={{ width: 56, height: 56 }}/>
          </Grid>
          <Grid item xs={6}>
            <Button sx={{ mt: 1 }} variant="contained" component="label">
              Zmień zdjęcie
              <input hidden accept="image/*" type="file" onChange={handleAvatarChange} />
            </Button>
          </Grid>
        </Grid>
        <FontSettings />
        <Typography variant='subtitle2'><FormattedMessage id='forms.profile.changePassword' /></Typography>
        <FormInputField<FormInput>
          {...{ control, errors }}
          name='password'
          defaultValue=''
          type='password'
          labelPath='forms.profile.password'
        />
        <FormInputField<FormInput>
          {...{ control, errors }}
          name='repeatedPassword'
          defaultValue=''
          type='password'
          labelPath='forms.profile.repeatPassword'
        />
        <Button variant='contained' sx={{ maxWidth: 200 }} type='submit'>
          <FormattedMessage id='forms.profile.save' />
        </Button>
      </FormStack>
    </form>
  );
};

export default ProfileForm;
