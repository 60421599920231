import { ChangeEvent, KeyboardEvent, memo, useState } from 'react';
import { Lesson } from '../../../types';
import styled from '@emotion/styled';
import { Box, Button, Chip, Modal, Stack, TextField, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { uniq } from 'lodash';
import ApiService from '../../../services/ApiService';
import { toast } from 'react-hot-toast';
import { useTranslation } from '../../../hooks/utils';
import style from './style';

type Props = {
  lessonId: string | undefined;
  tags: Lesson['tags'] | undefined;
  onClose: () => void;
  onSave: (tags: string[]) => void;
};

const FormStack = styled(Stack)`
  max-width: 480px;
  min-width: 320px;
  margin: 16px auto 0;
  display: flex;
  text-align: center;
`;

const LessonTagsModal = memo(
  function LessonTagsModal({ lessonId, tags, onClose, onSave }: Props) {
    const [tag, setTag] = useState<string | null>(null);
    const [tagsList, setTagsList] = useState<string[]>(tags ?? []);
    const translation = useTranslation();

    const handleAddTag = (event: KeyboardEvent<HTMLDivElement>) => {
      if(event.key === 'Enter') {
        event.preventDefault();
        if(tag) {
          setTagsList((prevTags) => (uniq([
            ...prevTags,
            tag,
          ])));
          setTag('');
        }
      }
    };

    const handleDelete = (tagToDelete: string) => () => {
      setTagsList((prevTags) => prevTags.filter((item) => item !== tagToDelete));
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      setTag(event.target.value);
    };

    const handleTagsSave = async () => {
      if(lessonId) {
        await ApiService.updateLessonMeta(lessonId, { tags: tagsList });
        onSave(tagsList);
        onClose();
        toast.success(translation( 'toasts.saveTagsSuccessful'));
      }
    };

    return (
      <Modal
        open
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h6" sx={{ marginBottom: 3 }}>
            <FormattedMessage id='components.lesson.tags.header' />
          </Typography>
          <FormStack spacing={2}>
            <TextField placeholder="Wpisz nowy tag" onKeyPress={handleAddTag} value={tag} onChange={handleInputChange}/>
            <Box>
              { tagsList.map((item) => (
                <Chip
                  key={item}
                  sx={{ margin: 0.5 }}
                  label={item}
                  onDelete={handleDelete(item)}
                />
              ))}
            </Box>
            <Button onClick={handleTagsSave} variant='contained' sx={{ maxWidth: 200, alignSelf: 'center' }}>
              <FormattedMessage id='components.lesson.tags.saveButton' />
            </Button>
          </FormStack>
        </Box>
      </Modal>
    );
  }
);

export default LessonTagsModal;
