import styled from '@emotion/styled';
import { Badge } from '@mui/material';
import { SocketStatus } from '../../enums';
import { getSocketIndicatorColor } from './utils';

type Props = {
  active: boolean;
  status: SocketStatus;
}

const SocketIndicatorBadge = styled(Badge)<Props>(props => ({
  '& .MuiBadge-badge': {
    display: props.active ? 'flex' : 'none',
    backgroundColor: getSocketIndicatorColor(props.status),
    color: getSocketIndicatorColor(props.status),
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

export default SocketIndicatorBadge;