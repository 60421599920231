import { Route, Routes } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import RegisterPage from './pages/RegisterPage';
import LessonPage from './pages/LessonPage';
import ProfilePage from './pages/ProfilePage';
import SharedLessonPage from './pages/SharedLessonPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import SocketUsageWrapper from './components/SocketUsageWrapper';
import StudyPage from './pages/StudyPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import MaintenancePage from './pages/MaintenancePage';
import PresentationPage from './pages/PresentationPage';

const RoutesList = () => {
  return (
    <Routes>
      <Route path='/' element={<HomePage />} />
      <Route path='/profile' element={<ProfilePage />} />
      <Route element={<SocketUsageWrapper />}>
        <Route path='/study/:sessionId' element={<StudyPage />} />
        <Route path='/presentation/:sessionId' element={<PresentationPage />} />
        <Route path='/lesson/:slug/:lessonId' element={<LessonPage />} />
      </Route>
      <Route path='/register' element={<RegisterPage />} />
      <Route path='/login' element={<LoginPage />} />
      <Route path='/change-password' element={<ResetPasswordPage />} />
      <Route path='/lesson/:slug/:token/share' element={<SharedLessonPage />} />
      <Route path='/privacy' element={<PrivacyPolicyPage />} />
      <Route path='/maintenance' element={<MaintenancePage />} />
    </Routes>
  );
};

export default RoutesList;
