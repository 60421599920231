import React from 'react';
import { useEditor } from '../../hooks/editor';
import { Stack, styled } from '@mui/material';
import ReactQuill from 'react-quill';
import { set } from 'lodash';
import { useSocket } from '../../hooks/socket';
import { DeltaStatic } from 'quill';
import DraggableContentItem from './DraggableContentItem';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { StrictModeDroppable } from '../StrictModeDroppable';
import { EditMode } from '../../enums';
import ContentItemSkeleton from './ContentItemSkeleton';
import { LessonStudySession } from '../../types';

const ContentEditorsBox = styled(Stack)<{ fullHeight?: boolean }>`
  overflow-y: ${({ fullHeight }) => fullHeight ? 'hidden' : 'scroll' };
  overflow-x: hidden;
  height: ${({ fullHeight }) => fullHeight ? '100%' : 'calc(100vh - 144px);' }
  margin-left: 4px;
  margin-top: 4px;
  
  @media print {
    height: 100% !important;
    overflow: visible !important;
  }
`;

type Props = {
  handleFocus: (editor: ReactQuill | null, id: string | null) => void;
  fullHeight?: boolean;
  studySession?: LessonStudySession | undefined;
}

const LessonContentEditors = ({ handleFocus, fullHeight, studySession }: Props) => {
  const { state: { content, editMode, isLoading }, addParagraph, reorderContent, setStatus, removeParagraph } = useEditor();
  const { socket } = useSocket();
  const isStudyHistoryMode = editMode === EditMode.STUDY_HISTORY;

  const handleChange = (id: string, type: 'header' | 'paragraph') => (value: string, delta: DeltaStatic) => {
    if (isLoading) {
      return;
    }
    const data = {
      id,
      change: {},
      meta: {},
    };

    if (editMode === EditMode.TEXT_EDIT) {
      set(data, `change.${type}`, delta);
      socket.emit('update-lesson-content', data);

      setStatus({
        isSaving: true,
        lastSaved: null,
      });
    }
  };

  const handleDelete = (id: string) => {
    removeParagraph(id);
  };

  const handleAddNewParagraph = (id: string) => {
    addParagraph({ after: id });
  };

  function onDragEnd(result: DropResult) {
    if(!result.destination) {
      return;
    }
    reorderContent({
      startIndex: result.source.index,
      endIndex: result.destination.index,
    });
  }

  return (
    <ContentEditorsBox fullHeight={fullHeight}>
      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId="droppable">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {
                (isLoading) &&
                [1,2,3,4].map((index) => (
                  <ContentItemSkeleton key={index} />
                ))
              }
              {
                content.data.map((item, index) => (
                  <DraggableContentItem
                    key={item.id}
                    index={index}
                    item={item}
                    evaluation={isStudyHistoryMode ? studySession?.evaluation[item.id] : null}
                    editorLoading={isLoading}
                    handleChange={handleChange}
                    handleFocus={handleFocus}
                    handleDelete={handleDelete}
                    onAddNewParagraph={handleAddNewParagraph}
                  />
                ))
              }
              {provided.placeholder}
            </div>
          )
          }
        </StrictModeDroppable>
      </DragDropContext>
    </ContentEditorsBox>
  );
};

export default LessonContentEditors;
