import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import ApiService from '../services/ApiService';
import type { LoginResponseData, SignInParams, SignUpParams } from '../services/types';
import { useLocation, useNavigate, useMatch, useSearchParams } from 'react-router-dom';
import { User } from '../types';
import { toast } from 'react-hot-toast';
import { useTranslation } from './utils';
import { StatusCode } from '../enums';
import useSWR, { preload } from 'swr';
import { useSessionStorage } from 'usehooks-ts';
import { useFlag, useFlagsStatus, useUnleashContext } from '@unleash/proxy-client-react';

type ProviderData = {
    user: User | null;
    signIn: (params: SignInParams) => Promise<LoginResponseData | null>;
    signUp: (params: SignUpParams) => Promise<null>;
    signOut: () => Promise<null>;
    helpers: {
        isTeacher: boolean;
        isStudent: boolean,
    }
    isLoading: boolean;
};

const authContext = createContext<ProviderData>({
  user: null,
  signIn: async () => null,
  signOut: async () => null,
  signUp: async () => null,
  helpers: {
    isTeacher: false,
    isStudent: false,
  },
  isLoading: true,
});

export function ProvideAuth({ children }: { children: ReactNode | ReactNode[] }) {
  const auth = useProvideAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

const fetcher = () => ApiService.auth.getUser();

preload('/user', fetcher);

const PUBLIC_ROUTES = ['/login', '/register', '/change-password', '/privacy', '/maintenance'];

function useProvideAuth() {
  const [user, setUser] = useState<User | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const matchPublicShare = useMatch('/lesson/:slug/:token/share');
  const matchPublicStudy = useMatch('/study/:sessionId');
  const matchPublicPresentationRoute = useMatch('/presentation/:sessionId');
  const translation = useTranslation();
  const { data: userData, mutate, isLoading } = useSWR('/user', fetcher);
  const [authRedirect, setAuthRedirect] = useSessionStorage<string | null>('auth-redirect', null);
  const updateContext = useUnleashContext();
  const maintenanceMode = useFlag('maintenance-mode');
  const flagsReady = useFlagsStatus();

  const isNotPublicRoute= !matchPublicShare && !matchPublicStudy && !matchPublicPresentationRoute;

  useEffect(() => {
    if(flagsReady && maintenanceMode) {
      return navigate('/maintenance');
    }

    if (userData && userData.statusCode !== StatusCode.OK) {
      if (!PUBLIC_ROUTES.includes(location.pathname) && isNotPublicRoute) {
        setAuthRedirect(location.pathname);
        navigate('/login');
      }

      setUser(null);
      return;
    }

    if (userData) {
      setUser(userData.data);
      updateContext({ userId: userData.data._id });
    }

    if (userData && userData.statusCode === StatusCode.OK && PUBLIC_ROUTES.includes(location.pathname)) {
      navigate('/');
    }
  }, [
    userData,
    location.pathname,
    navigate,
    isNotPublicRoute,
    setAuthRedirect,
    updateContext,
    maintenanceMode,
    flagsReady,
  ]);

  const signIn = async ({ email, password }: SignInParams) => {
    const result = await ApiService
      .auth
      .login({ email, password });
    await mutate();

    if (result.statusCode === StatusCode.OK) {
      const userResponse = await ApiService.auth.getUser();

      if (userResponse.statusCode === StatusCode.OK) {
        setUser(userResponse.data);
        updateContext({ userId: userResponse.data._id });
        const callbackUrl = searchParams.get('callbackUrl');

        if (callbackUrl) {
          setTimeout(() => {
            window.location.href = callbackUrl;
          }, 1000);
        } else if (authRedirect) {
          setTimeout(() => {
            navigate(authRedirect);
            setAuthRedirect(null);
          }, 1000);
        } else {
          setTimeout(() => {
            navigate('/');
          }, 1000);

        }
        return result.data;
      }
      toast.error(translation('errors.somethingWentWrong'));
    }

    if (result.statusCode === StatusCode.UNAUTHORIZED) {
      toast.error(translation('errors.cannotLogin'));
    } else {
      toast.error(translation('errors.somethingWentWrong'));
    }
    return null;
  };

  const signOut = async () => {
    await ApiService
      .auth
      .logout();
    await mutate();

    navigate('/login');
    setUser(null);
    return null;
  };

  const signUp = async ({ email, password, name }: SignUpParams) => {
    const result = await ApiService
      .auth
      .register({ email, password, name });

    if (result.statusCode === StatusCode.CONFLICT) {
      toast.error(translation('errors.emailOccupied'));
    } else if (result.statusCode === StatusCode.OK) {
      toast.success(translation('toasts.registered'));
      setUser(null);
      navigate('/login');
    } else {
      toast.error(translation('errors.somethingWentWrong'));
    }
    return null;

  };

  const helpers = {
    isTeacher: user?.role === 'teacher',
    isStudent: user?.role === 'student',
  };

  return {
    user,
    isLoading,
    signIn,
    signOut,
    signUp,
    helpers,
  };
}
