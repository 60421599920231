import React, { memo, useEffect, useState } from 'react';
import { Box, Button, ButtonGroup, InputAdornment, Modal, Stack, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { lessonJournalFormSchema } from '../../../../forms/validationSchemas';
import { SchoolSelect } from './SchoolSelect';
import { toast } from 'react-hot-toast';
import { useTranslation } from '../../../../hooks/utils';
import FormDatePicker from '../../../form/FormDatePicker';
import { DateTime } from 'luxon';
import { FormattedMessage } from 'react-intl';
import LessonCanceledRadioGroup, { LessonCanceled } from './LessonCanceledRadioGroup';
import { GroupSelect } from './GroupSelect';
import ApiService from '../../../../services/ApiService';
import useSWR from 'swr';
import { LessonEvidence, Result } from '../../../../types';
import FormInputField from '../../../form/FormInputField';

type Props = {
  lessonId?: string;
  onClose: () => void;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 320,
  maxWidth: '60%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  maxHeight: '100vh',
  overflowY: 'scroll'
};

const FormStack = styled(Stack)`
  max-width: 400px;
  margin: 16px auto 0;
  display: flex;
`;

type LessonJournalForm = {
  group: string;
  duration: string;
  canceled: boolean;
  canceledTooLate: boolean;
  dates: {
    placed: string;
  }
}

const fetcher = (lessonId: string | undefined) => () => ApiService.getLessonEvidence(lessonId);

const getCancelRadioValue = (canceled: boolean, canceledTooLate: boolean) => {
  if(canceled) {
    return LessonCanceled.ON_TIME;
  }
  if(canceledTooLate) {
    return LessonCanceled.TOO_LATE;
  }
  return null;
};

const getSchoolInitialValue = (data: Result<LessonEvidence> | undefined) => {
  if(data && data.statusCode === 200) {
    return data.data.school;
  }
  return undefined;
};

const getDurationFromNumber = (duration: number) => {
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;
  return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
};

const LessonJournalModal = memo(
  function LessonJournalModal({ lessonId, onClose }: Props) {
    const translation = useTranslation();
    const [selectedSchool, setSelectedSchool] = useState<string | undefined>(undefined);
    const { control, handleSubmit, formState: { errors }, setValue, reset, watch } = useForm<LessonJournalForm>({
      defaultValues: {
        dates: {
          placed: DateTime.now().set({ hour: 12 }).toISO()!,
        }
      },
      resolver: yupResolver(lessonJournalFormSchema),
    });

    const canceledWatch = watch('canceled');
    const canceledTooLateWatch = watch('canceledTooLate');

    const { data: evidenceData } = useSWR<Result<LessonEvidence>, Error>(`/lessons/${lessonId}/evidence`, fetcher(lessonId));

    useEffect(() => {
      if(evidenceData?.statusCode === 200) {
        setSelectedSchool(evidenceData.data.school);

        reset({
          group: evidenceData.data.group,
          canceled: evidenceData.data.canceled,
          duration: getDurationFromNumber(evidenceData.data.duration),
          canceledTooLate: evidenceData.data.canceledTooLate,
          dates: evidenceData.data.dates,
        });
      }
    }, [evidenceData, reset]);

    const onSubmit: SubmitHandler<LessonJournalForm> = async data => {
      const [hours, minutes] = data.duration.split(':');

      if (!lessonId) {
        return null;
      }

      await ApiService.updateLessonEvidence(lessonId, {
        ...data,
        duration: parseInt(hours) * 60 + parseInt(minutes),
        dates: {
          placed: DateTime.fromJSDate(new Date(data.dates.placed)).set({ hour: 12 }).toISO()!,
        }
      });

      toast.success(translation('toasts.evidenceUpdateSuccess'));
      onClose();
    };

    const setTimeValue = (time: string) =>{
      setValue('duration', time);
    };

    const handleLessonCanceledChange = (value: LessonCanceled | null) => {
      if(!value) {
        setValue('canceled', false);
        setValue('canceledTooLate', false);
      }
      if(value === LessonCanceled.ON_TIME) {
        setValue('canceled', true);
        setValue('canceledTooLate', false);
      }
      if(value === LessonCanceled.TOO_LATE) {
        setValue('canceled', false);
        setValue('canceledTooLate', true);
      }
    };

    return (
      <Modal
        open
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ marginBottom: 3 }}>
            Dziennik
          </Typography>
          <SchoolSelect onChange={setSelectedSchool} initialValue={getSchoolInitialValue(evidenceData)}/>
          <Box sx={{ marginTop: 2 }}>
            { selectedSchool && <GroupSelect control={control} errors={errors} schoolId={selectedSchool} /> }
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormStack spacing={2}>
              <FormDatePicker control={control} errors={errors} name="dates.placed" labelPath="forms.lessonJournal.date.label" />
              <FormInputField control={control} name='duration' labelPath='forms.lessonJournal.duration.label'
                errors={errors} type="time" inputProps={{ list: [] }} InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }} />
              <ButtonGroup variant="text" aria-label="text button group" sx={{ alignSelf: 'center' }}>
                <Button onClick={() => setTimeValue('00:45')}>{translation('forms.lessonJournal.time.buttons.45')}</Button>
                <Button onClick={() => setTimeValue('01:00')}>{translation('forms.lessonJournal.time.buttons.60')}</Button>
                <Button onClick={() => setTimeValue('01:30')}>{translation('forms.lessonJournal.time.buttons.90')}</Button>
              </ButtonGroup>
              <LessonCanceledRadioGroup initialValue={getCancelRadioValue(canceledWatch, canceledTooLateWatch)} onChange={handleLessonCanceledChange}/>
              <Button variant='contained' sx={{ maxWidth: 200, alignSelf: 'center' }} type='submit'>
                <FormattedMessage id='forms.profile.save' />
              </Button>
            </FormStack>
          </form>
        </Box>
      </Modal>
    );
  }
);

export default LessonJournalModal;
