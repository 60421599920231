import NavigationBar from '../components/NavigationBar/NavigationBar';
import { Box, Grid } from '@mui/material';
import { useAuth } from '../hooks/auth';
import ProfileForm from '../forms/ProfileForm';

const ProfilePage = () => {
  const { user } = useAuth();

  return (
    <>
      <NavigationBar path='profile' />
      <Box>
        <Grid padding={2}>
          {user &&
            <ProfileForm />
          }
        </Grid>
      </Box>
    </>
  );
};

export default ProfilePage;