import { io, Socket } from 'socket.io-client';

import { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { SocketStatus } from '../enums';
import { getSocketToken } from '../utils';

const socketOptions = {
  autoConnect: false,
  withCredentials: true,
  transports: ['websocket', 'polling'],
};

const mockSocket = {
  connect: () => {},
  emit: () => {},
  on: () => {},
} as unknown as Socket;

const socketUrl = process.env.REACT_APP_SOCKET_URL as string;
const socketUrlWithToken = `${socketUrl}?token=${getSocketToken()}`;

const useSocketConnection = () => {
  const { current: socket } = useRef<Socket>(io(socketUrlWithToken, socketOptions));
  const [socketStatus, setSocketStatus] = useState<SocketStatus>(SocketStatus.OFF);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    socket.on('connect', () => {
      setSocketStatus(SocketStatus.ON);
      setIsLoading(false);
    });

    socket.on('disconnect', () => {
      setSocketStatus(SocketStatus.OFF);
      setIsLoading(false);
    });

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.close();
    };
  }, [socket]);

  return { socket, socketStatus, isLoading };
};

const socketContext = createContext<{ socket: Socket, socketStatus: SocketStatus, isLoading: boolean }>({
  socket: mockSocket,
  socketStatus: SocketStatus.OFF,
  isLoading: true,
});

export function ProvideSocket({ children }: { children: ReactNode | ReactNode[] }) {
  const { socket, socketStatus, isLoading } = useSocketConnection();

  return <socketContext.Provider value={{ socket, socketStatus, isLoading }}>{children}</socketContext.Provider>;
}

export const useSocket = () => {
  const { socket, socketStatus, isLoading } = useContext(socketContext);

  return { socket, socketStatus, isLoading };
};
