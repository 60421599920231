import { ChangeEvent, memo, useState } from 'react';
import styled from '@emotion/styled';
import { Box, Button, Modal, Stack, TextField, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import ApiService from '../services/ApiService';
import { toast } from 'react-hot-toast';
import { useTranslation } from '../hooks/utils';

type Props = {
  onClose: () => void;
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 320,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  maxHeight: '100vh',
  overflowY: 'scroll'
};

const FormStack = styled(Stack)`
  max-width: 480px;
  min-width: 320px;
  margin: 16px auto 0;
  display: flex;
  text-align: center;
`;

const ResetPasswordModal = memo(
  function ResetPasswordModal({ onClose }: Props) {
    const [email, setEmail] = useState<string | null>(null);
    const translation = useTranslation();

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      setEmail(event.target.value);
    };

    const onResetButtonClick = async () => {
      if(email && email.length > 3) {
        await ApiService.auth.resetPassword({ email });
        onClose();
        toast.success(translation('toasts.resetPasswordEmailSentSuccessfully'));
      }
    };

    return (
      <Modal
        open
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h6" sx={{ marginBottom: 3 }}>
            <FormattedMessage id='components.login.resetPassword.header' />
          </Typography>
          <FormStack spacing={2}>
            <TextField placeholder="Wprowadź email" value={email} type="email" onChange={handleInputChange}/>
            <Button onClick={onResetButtonClick} variant='contained' sx={{ maxWidth: 200, alignSelf: 'center' }}>
              <FormattedMessage id='components.login.resetPassword.button' />
            </Button>
          </FormStack>
        </Box>
      </Modal>
    );
  }
);

export default ResetPasswordModal;
