import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useTranslation } from '../../../../hooks/utils';
import { useEffect } from 'react';

export enum LessonCanceled {
  ON_TIME = 'ON_TIME',
  TOO_LATE = 'TOO_LATE'
}

type Props = {
  onChange: (lessonCanceled: LessonCanceled | null) => void;
  initialValue: LessonCanceled | null;
}

export default function LessonCanceledRadioGroup({ onChange, initialValue }: Props) {
  const translation = useTranslation();
  const [value, setValue] = React.useState<LessonCanceled | null>(initialValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value as LessonCanceled);
    onChange((event.target as HTMLInputElement).value as LessonCanceled);
  };

  const resetIfSame = (buttonValue: LessonCanceled) => {
    if (value === buttonValue) {
      setValue(null);
      onChange(null);
    }
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <FormControl>
      <FormLabel id='lesson-canceled-radio-group-label'>{translation('forms.lessonJournal.canceled.label')}</FormLabel>
      <RadioGroup
        aria-labelledby='lesson-canceled-radio-group-label'
        name='lesson-canceled-radio-group'
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel value={LessonCanceled.ON_TIME}
          control={<Radio onClick={() => resetIfSame(LessonCanceled.ON_TIME)} />}
          label={translation('forms.lessonJournal.canceled.onTime')} />
        <FormControlLabel value={LessonCanceled.TOO_LATE}
          control={<Radio onClick={() => resetIfSame(LessonCanceled.TOO_LATE)} />}
          label={translation('forms.lessonJournal.canceled.tooLate')} />
      </RadioGroup>
    </FormControl>
  );
}
