import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Button, Box, Checkbox, FormControlLabel, Stack, Typography, Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { loginFormSchema } from './validationSchemas';
import FormInputField from '../components/form/FormInputField';
import { useAuth } from '../hooks/auth';
import { useTranslation } from '../hooks/utils';
import { useModal } from 'react-modal-hook';
import ResetPasswordModal from '../components/ResetPasswordModal';

interface FormInput {
  email: string;
  password: string;
  rememberMe: boolean;
}

const ShowPasswordGridButton = styled(Grid)`
  display: flex;
  justify-content: end;
  align-items: center;
  cursor: pointer;
  text-decoration: underline;
`;

const ForgotPasswordLink = styled(Typography)`
  text-decoration: underline;
  cursor: pointer;
`;

const RegisterBox = styled(Box)`
  height: 64px;
  margin-top: 48px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

const FormStack = styled(Stack)`
  max-width: 400px;
  margin: 40px auto 0;
  display: flex;
  align-items: center
`;

const LoginForm = () => {
  const { control, handleSubmit, formState: { errors } } = useForm<FormInput>({
    defaultValues: {
      rememberMe: false,
    },
    resolver: yupResolver(loginFormSchema),
  });
  const translation = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const { signIn } = useAuth();
  const [showModal, hideModal] = useModal(() => <ResetPasswordModal onClose={hideModal} />);

  const handleShowPasswordClick = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const onSubmit: SubmitHandler<FormInput> = async data => {
    await signIn(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormStack spacing={3}>
        <FormInputField<FormInput>
          {...{ control, errors }}
          name='email'
          defaultValue=''
          type='email'
          labelPath='forms.login.email'
        />
        <FormInputField<FormInput>
          {...{ control, errors }}
          name='password'
          defaultValue=''
          type={showPassword ? 'text' : 'password'}
          labelPath='forms.login.password'
        />
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <Controller
              name='rememberMe'
              control={control}
              render={({ field }) =>
                <FormControlLabel
                  control={<Checkbox {...field} />}
                  label={translation('forms.login.rememberMe')}
                />
              }
            />
          </Grid>
          <ShowPasswordGridButton item xs={6}>
            <Typography onClick={handleShowPasswordClick}>
              <FormattedMessage id='forms.login.showPassword' />
            </Typography>
          </ShowPasswordGridButton>
        </Grid>
        <Button variant='contained' sx={{ maxWidth: 200 }} type='submit'>
          <FormattedMessage id='forms.login.loginButton' />
        </Button>
        <ForgotPasswordLink onClick={showModal}><FormattedMessage id='forms.login.forgotPassword' /></ForgotPasswordLink>
        <RegisterBox>
          <Typography><FormattedMessage id='forms.login.noAccount' /></Typography>
          <Link to='/register'>
            <Typography><FormattedMessage id='forms.login.register' /></Typography>
          </Link>
        </RegisterBox>
      </FormStack>
    </form>
  );
};

export default LoginForm;
