import NavigationBar from '../components/NavigationBar/NavigationBar';
import { Box, Grid } from '@mui/material';
import RegisterForm from '../forms/RegisterForm';

const RegisterPage = () => {

  return (
    <>
      <NavigationBar path='register' />
      <Box>
        <Grid padding={2}>
          <RegisterForm />
        </Grid>
      </Box>
    </>
  );
};

export default RegisterPage;