import { SocketStatus } from '../../enums';
import { FormattedMessage } from 'react-intl';

export const getSocketIndicatorTooltip = (status: SocketStatus, activeIndicator?: boolean) => {
  if(!activeIndicator) return null;

  switch (status) {
  case SocketStatus.ON:
    return <FormattedMessage id="tooltips.socketConnectionOn" />;
  case SocketStatus.WARN:
    return <FormattedMessage id="tooltips.socketConnectionWarn" />;
  case SocketStatus.OFF:
    return <FormattedMessage id="tooltips.socketConnectionOff" />;
  default:
    return null;
  }
};

export const getSocketIndicatorColor = (status: SocketStatus) => {
  switch (status) {
  case SocketStatus.ON:
    return '#44b700';
  case SocketStatus.WARN:
    return '#F2B829';
  case SocketStatus.OFF:
    return '#B8090E';
  default:
    return '#44b700';
  }
};
