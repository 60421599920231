import { Control, Controller, FieldErrors, FieldValues, Path, PathValue } from 'react-hook-form';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { useTranslation } from '../../hooks/utils';
import { Primitive } from '../../types';

type Props<T extends FieldValues> = {
  control: Control<T, string>;
  errors: FieldErrors<T>;
  name: Path<T>;
  defaultValue?: PathValue<T, Path<T>>,
  labelPath: string;
  options: { label: string, value: Primitive }[];
}

const FormSelect = <T extends FieldValues>({ control, name, defaultValue, errors, options, labelPath }: Props<T>) => {
  const translation = useTranslation();
  const helperText = errors[name]?.message && translation(`errors.${errors[name]?.message}`);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) =>
        <FormControl fullWidth>
          <InputLabel id={`${name}-label`}>{translation(labelPath)}</InputLabel>
          <Select
            {...field}
            // @ts-ignore
            onChange={field.onChange}
            labelId={`${name}-label`}
            id={name}
            error={!!errors[name]}
            label={translation(labelPath)}
          >
            { options.map(({ label, value }) => (
              <MenuItem key={`${name}-${label}`} value={value}>{label}</MenuItem>
            ))}
          </Select>
          { helperText && <FormHelperText sx={{ color: 'red' }}>{helperText}</FormHelperText> }
        </FormControl>}
    />
  );
};

export default FormSelect;
