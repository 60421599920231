import {
  createContext,
  ReactNode,
  useContext, useReducer, useState,
} from 'react';
import ApiService from '../services/ApiService';
import { Lesson, Result } from '../types';
import useSWRInfinite from 'swr/infinite';
import queryString from 'query-string';

type EditorsContext = {
  state: State;
  lessons: Lesson[] | undefined,
  fetchMore: () => void;
  isLoading: boolean;
  hasMore: boolean;
  refetch: () => void;
  clear: () => void;
  setFilters: (filters: Partial<Filters>) => void;
}

const initialState: State = {
  filters: {
    query: '',
    dateType: 'created',
    dateRange: {
      from: null,
      to: null,
    },
  },
};

const initialContext = {
  state: initialState,
  lessons: [],
  isLoading: false,
  refetch: () => {},
  clear: () => {},
  fetchMore: () => {},
  hasMore: true,
  setFilters: () => {},
};

type Filters = {
  dateType: 'created' | 'shared',
  dateRange: {
    from: Date | null,
    to: Date | null,
  }
  query: string;
}

type State = {
  filters: Filters;
}

const editorsContext = createContext<EditorsContext>(initialContext);

enum ActionKind {
  SET_FILTERS = 'SET_FILTERS',
}

type SetFiltersAction = {
  type: ActionKind.SET_FILTERS,
  payload: Partial<Filters>
}

type Action = SetFiltersAction;

const reducer = (state: State, action: Action) => {
  switch (action.type) {
  case ActionKind.SET_FILTERS:
    return {
      ...state,
      filters: {
        ...state.filters,
        ...action.payload,
      },
    };
  default:
    return state;
  }
};

const PAGE_LIMIT = 20;

const fetcher = (url: string) => ApiService.get<Lesson[]>(url);

const getTransformedFilters = (filters: Filters) => {
  if (filters.dateType === 'created') {
    return ({
      createdFrom: filters.dateRange.from,
      createdTo: filters.dateRange.to,
      search: filters.query,
    });
  } else if (filters.dateType === 'shared') {
    return ({
      sharedFrom: filters.dateRange.from,
      sharedTo: filters.dateRange.to,
      search: filters.query,
    });
  }
  return ({
    search: filters.query,
  });
};

export function ProvideLessons({ children }: { children: ReactNode | ReactNode[] }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [hasMore, setHasMore] = useState(true);
  const filtersQueryString = queryString.stringify(getTransformedFilters(state.filters), { skipNull: true, skipEmptyString: true });

  // TODO - handle error
  const { data, isLoading, mutate, size, setSize } = useSWRInfinite(
    (index, previousPageData: Result<Lesson[]>) => {
      if (previousPageData && previousPageData.statusCode === 200 && previousPageData.data.length === 0) {
        setHasMore(false);
        return null;
      }
      return `/lessons?${filtersQueryString}&limit=${PAGE_LIMIT}&offset=${PAGE_LIMIT * index}`;
    }, fetcher, {
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateFirstPage: false,
      parallel: true,
    }
  );

  const isLoadingMore = !!(isLoading || (size > 0 && data && typeof data[size - 1] === "undefined"));

  const lessons = data?.flatMap((result) => {
    if(result.statusCode === 200) {
      return result.data;
    }
    return [];
  });

  const setFilters = (filters: Partial<Filters>) => {
    dispatch({
      type: ActionKind.SET_FILTERS,
      payload: filters,
    });
  };

  const fetchMore = async () => {
    await setSize((prevSize) => prevSize + 1);
  };

  const clear = async () => {
    await setSize(1);
  };

  const value = {
    state,
    lessons,
    fetchMore,
    refetch: mutate,
    hasMore,
    clear,
    isLoading: isLoadingMore,
    setFilters,
  };

  return <editorsContext.Provider value={value}>{children}</editorsContext.Provider>;
}

type Setters = {
  setQuery: (query: string) => void;
  setDateType: (dateType: Filters['dateType']) => void;
  setDateFrom: (from: Date | null) => void;
  setDateTo: (to: Date | null) => void;
  resetFilters: () => void;
}

export const useLessons = () => {
  const { lessons, fetchMore, refetch, clear, isLoading, hasMore } = useContext(editorsContext);

  return { lessons, fetchMore, refetch, clear, isLoading, hasMore };
};

export const useFilters = (): [Filters, Setters] => {
  const { state: { filters }, setFilters } = useContext(editorsContext);

  const setters = {
    setQuery: (query: string) => {
      setFilters({
        query,
      });
    },
    setDateType: (dateType: Filters['dateType']) => {
      setFilters({
        ...filters,
        dateType,
      });
    },
    setDateFrom: (from: Date | null) => {
      setFilters({
        ...filters,
        dateRange: {
          ...filters.dateRange,
          from,
        },
      });
    },
    setDateTo: (to: Date | null) => {
      setFilters({
        ...filters,
        dateRange: {
          ...filters.dateRange,
          to,
        },
      });
    },
    resetFilters: () => {
      setFilters(initialState.filters);
    },
  };

  return [filters, setters];
};
