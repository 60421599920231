import useSWR from 'swr';
import { Result, UserSchools } from '../../../../types';
import ApiService from '../../../../services/ApiService';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from '../../../../hooks/utils';

type Props = {
  onChange: (schoolId: string) => void;
  initialValue?: string | undefined;
}

const fetcher = () => ApiService.getUserSchoolsList();

export const SchoolSelect = ({ onChange, initialValue }: Props) => {
  const translation = useTranslation();
  const [value, setValue] = useState<string>('');
  const { data, error } = useSWR<Result<UserSchools>, Error>('/user/schools', fetcher);

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value as string);
    onChange(event.target.value as string);
  };

  useEffect(() => {
    if(initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  return (
    <FormControl fullWidth error={!!error}>
      <InputLabel id="school-select-label">{translation('forms.lessonJournal.school.label')}</InputLabel>
      <Select
        labelId="school-select-label"
        id="school-select"
        value={value}
        label={translation('forms.lessonJournal.school.label')}
        onChange={handleChange}
      >
        { data?.statusCode === 200 && data.data.map((school) => (
          <MenuItem key={school.school.id} value={school.school.id}>{school.school.name}</MenuItem>
        )) }
      </Select>
      { error && <FormHelperText>Błąd</FormHelperText>}
    </FormControl>
  );
};
