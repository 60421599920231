import { memo, useState } from 'react';
import { Box, Button, Modal, Stack, Typography, useMediaQuery } from '@mui/material';
import styled from '@emotion/styled';
import style from './style';
import { FormattedMessage } from 'react-intl';
import { useCopyToClipboard } from 'usehooks-ts';
import { useSocket } from '../../../hooks/socket';
import { useNavigate } from 'react-router-dom';
import { EditMode, StudyEvaluationMode } from '../../../enums';
import ReactGA from 'react-ga4';
import { useFlag } from '@unleash/proxy-client-react';
import { toast } from 'react-hot-toast';

type Props = {
    lessonId?: string;
    onClose: () => void;
    onSetEditMode: (editMode: EditMode) => void;
    onSetSessionId: (sessionId: string) => void;
}

const ModalStack = styled(Stack)`
    max-width: 480px;
    min-width: 300px;
    margin: 16px auto 0;
    display: flex;
    text-align: center;
    align-items: center;
`;

const PickMode = ({ onModePick }: { onModePick: (mode: StudyEvaluationMode) => void }) => {
  const teachingPresentationEnabled = useFlag('teaching-presentation');
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <>
      <Typography>
        <FormattedMessage id='components.lesson.study.mode.pick.header' />
      </Typography>
      <Button variant='contained' onClick={() => onModePick(StudyEvaluationMode.SINGLE)}
        sx={{ mt: 3, width: '240px' }}>
        <FormattedMessage id='components.lesson.study.mode.pick.singleButton' />
      </Button>
      <Button variant='contained' onClick={() => onModePick(StudyEvaluationMode.COOPERATIVE)}
        sx={{ mt: 3, width: '240px' }}>
        <FormattedMessage id='components.lesson.study.mode.pick.cooperativeButton' />
      </Button>
      { teachingPresentationEnabled && !isMobile &&
        <Button variant='contained' onClick={() => onModePick(StudyEvaluationMode.TEACHING)}
          sx={{ mt: 3, width: '240px' }}>
          <FormattedMessage id='components.lesson.study.mode.pick.teachingButton' />
        </Button>
      }
    </>
  );
};

const InitializeCooperative = ({ onClick }: { onClick: () => void }) => {
  return (
    <>
      <Stack>
        <Typography variant='h6'>
          <FormattedMessage id='components.lesson.study.mode.init.header' />
        </Typography>
        <Typography variant='h6' sx={{ mt: 1 }}>
          <FormattedMessage id='components.lesson.study.mode.init.subheader' />
        </Typography>
      </Stack>
      <Button variant='contained' onClick={onClick} sx={{ mt: 3, width: '200px' }}>
        <FormattedMessage id='components.lesson.study.mode.init.button' />
      </Button>
    </>
  );
};

type StudySessionState = {
    id: string;
    mode: StudyEvaluationMode;
}

type PresentationSessionState = {
    id: string;
}

const LessonStudyModal = memo(
  function LessonStudyModal({ lessonId, onClose, onSetEditMode, onSetSessionId }: Props) {
    const { socket } = useSocket();
    const [state, setState] = useState<StudySessionState | null>(null);
    const [, copy] = useCopyToClipboard();
    const navigate = useNavigate();
    const isCooperative = state?.mode === StudyEvaluationMode.COOPERATIVE;
    const isTeaching = state?.mode === StudyEvaluationMode.TEACHING;

    const handleModePick = (mode: StudyEvaluationMode) => {
      ReactGA.event({
        category: 'study',
        action: `pickStudyMode_${mode}`,
      });

      if (mode === StudyEvaluationMode.TEACHING) {
        onSetEditMode(EditMode.CONTENT_PRESENTATION);
        socket.emit('presentation:session', {
          lessonId,
        }, (err: string, data: PresentationSessionState) => {
          setState({
            ...data,
            mode: StudyEvaluationMode.TEACHING
          });
          onSetSessionId(data.id);
        });
        return;
      }

      socket.emit('study:session', {
        lessonId,
        mode,
      }, (err: string, data: StudySessionState) => {
        setState(data);
        if (mode === StudyEvaluationMode.SINGLE) {
          navigate(`/study/${data?.id}`);
        }
      });
    };

    const handleCopySessionLinkClick = async () => {
      if (isCooperative) {
        await copy(`${window.location.origin}/study/${state?.id}`);
        navigate(`/study/${state?.id}`);
      }
      if(isTeaching) {
        await copy(`${window.location.origin}/presentation/${state?.id}`);
        toast.success('Link został skopiowany do schowka');
      }
      onClose();
    };

    return (
      <Modal
        open
        onClose={onClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <ModalStack spacing={2}>
            {!state && <PickMode onModePick={handleModePick} />}
            {
              (isCooperative || isTeaching) &&
                      <InitializeCooperative onClick={handleCopySessionLinkClick} />
            }
          </ModalStack>
        </Box>
      </Modal>
    );
  },
);

export default LessonStudyModal;
