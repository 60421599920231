
export enum SocketStatus {
  ON = 'ON',
  OFF = 'OFF',
  WARN = 'WARN'
}

export enum LessonType {
  BLOCK = 'block',
  FLASHCARDS = 'flashcards'
}

export enum StatusCode {
  OK = 200,
  CONFLICT = 409,
  NOT_FOUND = 404,
  UNAUTHORIZED = 403,
}

export enum EditMode {
  READ_ONLY = 'READ_ONLY',
  TEXT_EDIT = 'TEXT_EDIT',
  CONTENT_EDIT = 'CONTENT_EDIT',
  STUDY_HISTORY = 'STUDY_HISTORY',
  CONTENT_PRESENTATION = 'CONTENT_PRESENTATION',
}

export enum StudyEvaluationRole {
  LEARNER = 'LEARNER',
  EVALUATOR = 'EVALUATOR'
}

export enum StudyEvaluationMode {
  SINGLE = 'SINGLE',
  COOPERATIVE = 'COOPERATIVE',
  TEACHING = 'TEACHING'
}

export enum StudyEvaluationGrade {
  GREAT = 'GREAT',
  NOT_BAD = 'NOT_BAD',
  REPEAT = 'REPEAT'
}
