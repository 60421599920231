import { MutableRefObject, useContext } from 'react';
import { useSocket } from '../socket';
import { getNewContentItem, getReorderedContentData, insert, isParagraphIdLast } from './utils';
import { ActionKind } from './enums';
import { LessonContentItem } from '../../types';
import { EditMode, StudyEvaluationGrade } from '../../enums';
import ReactQuill from 'react-quill';
import { ReorderContentAction } from './types';
import { editorsContext } from './context';
import ReactGA from "react-ga4";

export const useEditor = () => {
  const { state, dispatch, currentEditor, currentEditorId, setCurrentEditorId, editors } = useContext(editorsContext);
  const { socket } = useSocket();

  const addParagraph = ({ after }: { after?: string }) => {
    const contentItem = getNewContentItem();
    const index = state.content.data.findIndex((item) => item.id === after);

    dispatch({
      type: ActionKind.ADD_CONTENT_ITEM,
      payload: {
        after,
        item: contentItem,
      },
    });

    // Save empty new paragraph
    socket.emit('update-lesson-content', contentItem);

    if(!after) {
      socket.emit('reorder-lesson-content', {
        order: [...state.content.data, contentItem].map((item) => item.id),
      });
    }

    if (after) {
      socket.emit('reorder-lesson-content', {
        order: insert<LessonContentItem>(state.content.data, index + 1, contentItem).map((item) => item.id),
      });
    }
  };

  const removeParagraph = (id: string) => {
    dispatch({
      type: ActionKind.REMOVE_CONTENT_ITEM,
      payload: { id },
    });

    // Save empty new paragraph
    socket.emit('remove-lesson-content-item', { id });
  };

  const setEditMode = (editMode: EditMode) => {
    ReactGA.event({
      category: 'editor',
      action: 'editModeClick',
      label: editMode,
    });

    dispatch({
      type: ActionKind.SET_EDIT_MODE,
      payload: {
        editMode,
      }
    });

    if(editMode === EditMode.TEXT_EDIT) {
      const editorsIterator = editors.values();
      const { value } = editorsIterator.next();

      const editor = value.current as ReactQuill;

      if (editor) {
        setTimeout(() => {
          const length = editor.unprivilegedEditor?.getLength();
          const selection = { index: length || 0, length: length || 0 };
          editor.getEditor().setSelection(selection);
        }, 100);
      }
    }
  };

  const reorderContent = ({ startIndex, endIndex }: { startIndex: number, endIndex: number }) => {
    const action: ReorderContentAction = {
      type: ActionKind.REORDER_CONTENT,
      payload: {
        startIndex,
        endIndex,
      },
    };

    dispatch(action);

    socket.emit('reorder-lesson-content', {
      order: getReorderedContentData(state, action).map((item) => item.id),
    });
  };

  const isLatestParagraph = (id: string) => {
    return isParagraphIdLast(state, id);
  };

  const setStatus = ({ isSaving, lastSaved }: { isSaving: boolean, lastSaved: Date | null }) => {
    dispatch({
      type: ActionKind.CHANGE_STATUS,
      payload: {
        isSaving,
        lastSaved,
        connectionError: false,
      }
    });
  };

  const setPresentationFocus = (id: string) => {
    if(state.editMode === EditMode.CONTENT_PRESENTATION) {
      socket.emit('presentation:focus', { editorId: id, sessionId: state.presentationSessionId }, () => {
        setCurrentEditorId(id);
      });
    }
  };

  const setEditor = (id: string, editor: MutableRefObject<ReactQuill | null>) => {
    editors.set(id, editor);
  };

  const setTags = (tags: string[]) => {
    dispatch({
      type: ActionKind.SET_TAGS,
      payload: { tags }
    });
  };

  const setStudySessionId = (studySessionId: string | null) => {
    dispatch({
      type: ActionKind.SET_STUDY_SESSION_ID,
      payload: { id: studySessionId }
    });
  };

  const setPresentationSessionId = (sessionId: string | null) => {
    dispatch({
      type: ActionKind.SET_PRESENTATION_SESSION_ID,
      payload: { id: sessionId }
    });
  };

  const setStudySessionFilters = (studySessionFilters: StudyEvaluationGrade[]) => {
    dispatch({
      type: ActionKind.SET_STUDY_SESSION_FILTERS,
      payload: { filters: studySessionFilters }
    });
  };

  return {
    state,
    addParagraph,
    removeParagraph,
    currentEditor,
    currentEditorId,
    reorderContent,
    setCurrentEditorId,
    setPresentationFocus,
    setPresentationSessionId,
    setEditor,
    setEditMode,
    isLatestParagraph,
    setStatus,
    setTags,
    setStudySessionId,
    setStudySessionFilters,
  };
};
